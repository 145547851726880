import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoaderComponent } from './loader/loader.component';
import { MapComponent } from './map/map.component';

const routes: Routes = [
  {
    path:"",
    component:LoaderComponent
  },
  {
    path:"mapselector",
    component:LandingPageComponent
  }

]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  
 }
