import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ILogUserInResponse, LoginStatus } from '../app.model';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  email: string = '';
  password: string = '';
  stayLoggedIn : boolean = false;
  //loginStatus: LoginStatus;
  showStatusMessage: boolean = false;
  statusMessageHTML: string = '';
  environment = environment;
  signInClickedflag : boolean = false;

 
 @Output() public userLoggedIn = new EventEmitter<any>();

  constructor(private _loginService: LoginService,
    private _route: ActivatedRoute,
    private _router :Router) { }

  ngOnInit(): void {
    //call getSession if session exists navigate to next page or else show login challenge
    // this._loginService.getSession().subscribe((response)=>{
    //   if(response.isLoggedIn){
    //     this.userLoggedIn.emit();
    //     this._router.navigate(['/mapSelector']);
    //   }
    // })

    this._loginService.getSessionFromGuid().subscribe((response)=>{
      if(response.IsLoggedOn){
        this.userLoggedIn.emit(response);
        this._router.navigate(['/mapselector']);
      }
    })
  }

  registerClicked(){
    window.location.href = environment.loginServiceUrl;
  }

  
  signInClicked(){
    this.signInClickedflag = true;
    this._loginService.LogUserIn(this.email,this.password,this.stayLoggedIn).subscribe((response : ILogUserInResponse)=>{
      this.signInClickedflag = false;
      if(response.LoginReturnCode == LoginStatus.LoginSuccessful){
        //get user session
        this._loginService.getSessionFromGuid(response.CacheGUID).subscribe((res)=>{
          if(res.IsLoggedOn){
            this.userLoggedIn.emit(res);
          }
        })
       this._router.navigate(['/mapselector'],{ queryParams: { guid: response.CacheGUID }} );
        //let parent appcomponent know about sign in
        

      }
      else{
        this.signInClickedflag = false;
        alert("error")
      }
    })
  }
}
