import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './loader/loader.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MapComponent } from './map/map.component';
import { ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchService } from './search.service';
import {MappingService} from './mapping.service';
import {UndoRedoService} from './undoredo.service';
import {BasketService} from './basket.service';
import {ProductService} from './product.service';
import {PlansService} from './plans.service';
import {FeatureService} from './feature.service';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { BasketConfirmationComponent } from './basket-confirmation/basket-confirmation.component';
import { BasketPreviewComponent } from './basket-preview/basket-preview.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FormsModule } from '@angular/forms';

declare var require: any;

// This automatically creates SelectorHookParsers for each listed component:
const componentParsers: Array<HookParserEntry> = [
  {component: MapComponent},
  // ...
];

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    LandingPageComponent,
    MapComponent,
    BasketConfirmationComponent,
    BasketPreviewComponent,
    FeedbackComponent
  ],
  // entryComponents: [ MapComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    DynamicHooksModule.forRoot({
      globalParsers: componentParsers
    }),
  ],
  providers: [
    SearchService,
    MappingService,
    UndoRedoService,
    BasketService,
    ProductService,
    PlansService,
    FeatureService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
