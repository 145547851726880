import { Component, ComponentRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import {LoginService} from '../app/login.service';
import { LoaderComponent } from './loader/loader.component';
import { IBasketCountResponse, IGetUserCacheResponse } from './app.model';
import { of } from 'rxjs';
import { BasketService } from './basket.service';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { BasketPreviewComponent } from './basket-preview/basket-preview.component';
import { Modal } from 'node_modules/bootstrap/dist/js/bootstrap.esm.min.js';

declare var refreshNotifications;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Plans';
  appMenuActive :boolean = false;
  environment = environment;
  notificationMenuActive : boolean = false;
  infoMenuActive : boolean = false;
  userMenuActive : boolean = false;
  userNotSignedIn : boolean = true;
  basketCount : number;
  //isGuestUser : boolean = true;
  guestUserMenuActive : boolean = false;
  userCacheObject : IGetUserCacheResponse = {
  CompanyName: null,
  EmailAddress: null,
  ImpersonatorID: null,
  IsImpersonator: null,
  IsLoggedOn: false,
  IsGuest: false,
  PersonGuid: null,
  PersonName: null,
  SessionId: null,
  Membership: null
  };
  private _guid :string;
//@ViewChild('loader') _loader : LoaderComponent
@ViewChild('notificationMenu') private _notificationMenu: ElementRef;
@ViewChild(BasketPreviewComponent) _basketPreviewComponent: BasketPreviewComponent;
@ViewChild('feedbackModal') _feedbackPopup: ElementRef;
  showbasket: boolean = false;
  private _feedbackModal: any;

  constructor(private _route: ActivatedRoute,
    private _router :Router,
    private _cookieService: CookieService,
    private _loginService : LoginService,
    private _basketService : BasketService
    ){
      
  }

  ngOnInit(){
      //include notifications js incase its not loaded in index.html
      this.loadJsFile("https://www.emapsite.com/UI/Dist/JS/Notifications.js?v=2"); 

      this._route.queryParams.subscribe(params => {
        let guidName = environment.configurations.appConfig.cookies.guid.name;
      this._guid = params.guid?params.guid:this._cookieService.get(guidName);
      });
  
  }

  ngAfterViewInit(){
    try{
      refreshNotifications();
  }
  catch(e){
      console.log("notification error")
      window.location.reload();
  }
 this._basketPreviewComponent.basketUpdated.subscribe((count)=>{
   this.basketCount = count;
 })

 this._feedbackModal = new Modal(this._feedbackPopup.nativeElement);

  }


  appsHide(){
    this.appMenuActive = false;
  }

  appsClick(){
    this.appMenuActive = true;
  }

  
  feedbackClick(){
    this.infoHide();
    //open feedback modal
    setTimeout(()=>{
      this._feedbackModal.show();
    },100)
  }

openUrl(url : string){
    let location: Location = window.location; 
    location.href = url;
}

openLink(url :string){
 let win = window.open(url,'_blank');
}
infoClick() {
  this.infoMenuActive = !this.infoMenuActive
  // if (this.infoMenuActive = !this.infoMenuActive) {
  //     this._infoMenu.nativeElement.focus();
  // }
}
infoHide() {
  this.infoMenuActive = false;
}

notificationClick() {
  if (this.notificationMenuActive = !this.notificationMenuActive) {
      this._notificationMenu.nativeElement.focus();
  }
}
notificationHide() {
  this.notificationMenuActive = false;
}

guestUserClick() {
  // if (this.guestUserMenuActive = !this.guestUserMenuActive) {
  //     this._guestUserMenu.nativeElement.focus();
  // }
  this.guestUserMenuActive = !this.guestUserMenuActive;
}
guestUserHide() {
  this.guestUserMenuActive = false;
}

userClick() {
  // if (this.userMenuActive = !this.userMenuActive) {
  //     this._userMenu.nativeElement.focus();
  // }
  this.userMenuActive = true;
}
userHide() {
  this.userMenuActive = false;
}

signOutClick(){
    this._loginService.DeleteUserCache(this._guid).subscribe((resp:Response)=>{
    this._loginService.deleteCookie();
    window.location.href = `${environment.baseEmapsiteUrl}`; 
  })
}

// signInClick(){
// //what to do when guest user clicks sign in? navigate them to the login page
// //this._loginService.LogUserIn(this.)

// }

registerClicked(){
  this.openUrl(environment.loginServiceUrl )
}

showRevertToClassicApps(){

}

public loadJsFile(url:any){
  let node = document.createElement('script');  
  node.src = url;  
  node.type = 'text/javascript';  
  document.getElementsByTagName('head')[0].appendChild(node);
}

subscribeToEmmiter(ComponentRef){
  if((ComponentRef instanceof LoaderComponent)){
    const child :LoaderComponent = ComponentRef;
    child.userLoggedIn.subscribe((userObj:IGetUserCacheResponse)=>{
    
      this.userNotSignedIn = false;
      this.userCacheObject = userObj;
      //get basket count if the user is logged in
      this.getBasketCount();
    })
  }
  if((ComponentRef instanceof LandingPageComponent)){
    const landingPage :LandingPageComponent = ComponentRef;
    landingPage.addedToBasket.subscribe(()=>{
         //get basket count if the user is logged in
      this.getBasketCount();
    })
    landingPage.userLoggedIn.subscribe((userObj:IGetUserCacheResponse)=>{
    
      this.userNotSignedIn = false;
      this.userCacheObject = userObj;
      //get basket count if the user is logged in
      this.getBasketCount();
    })
  }
 }

getBasketCount(){

  this._basketService.GetBasketCount(this._guid).subscribe((response : IBasketCountResponse)=>{
    this.basketCount = response.ItemCount;
  })

}

getUserCacheObject():IGetUserCacheResponse{
  return this.userCacheObject;
}

// unsubscribe(){
//   if(this.susbcription){
//     this.susbcription.unsubscribe();
//   }
//   }

basketHide(){
  this.showbasket = false;
}

showBasket(){
  this.showbasket = true;
}

}
