import { Collection, Feature } from "ol";

  export enum WebChannel{
    Mapshop,
    Reports,
    Marine,
    Streets,
    Clink,
    Welsh,
    Plans,
    Express,
    Sitefinder
  }

  export enum LoginStatus {
    // These are valid return codes from the backend login status
    LoginSuccessful = 0,
    ErrorOccurred = 1,
    UserNotActivated = 2,
    UserLockedOut = 3,
    UserAccountLocked = 4,
    IncorrectLoginDetails = 5,
    LoginInputsValid = 6,
    NotApplicable = 7,
    // Client side code, indicating that login hasn't yet been attempted
    LoginNotYetAttempted = 8,
    // Client side code, indicating that the user doesn't have access to a particular service
    NoGroupMembership = 9
}

export interface ILogUserInResponse {
  AutoLoginReturnStatus: number,
  BasketID: number,
  CacheGUID: string,
  CompanyId: number,
  CompanyName: string,
  EmailAddress: string,
  ImpersonatorID: number,
  IsAutoLogin: boolean,
  IsImpersonator: boolean,
  LockOutUntil: number,
  LoginReturnCode: number,
  Membership: Array<any>,
  Page: string,
  PersonID: number,
  PersonName: string,
  SessionID: number,
  SitePreference: number 
}

export interface ILoginServiceResponse {
  isSuccess: boolean,
  isLoggedIn: boolean,
  loginStatus: LoginStatus
}
export interface IGetUserCacheResponse {
  BasketId?: number,
  CompanyId?: number, 
  CompanyName: string,
  EmailAddress: string,
  ImpersonatorID: number,
  IsImpersonator: boolean,
  IsLoggedOn: boolean,
  IsGuest: boolean,
  PersonGuid: string,
  PersonId?: number,
  PersonName: string,
  SessionId: number,
  Membership: Array<any>
}

export interface ISearchResult {
  Category: string;
  Crs: number;
  Description: string;
  Relevance: number;
  Restricted: boolean;
  Scale?: number;
  SortString: string;
  Suggestion: boolean;
  Type: string;
  X?: number;
  Y?: number;
  Wkt?: string;
  Id?:number;
  Name?:string;
  SearchesFoundID?:number;
  SearchesID?:number;
  TitleNumbers:[{
    Title : string,
    Wkt : any
  }]
  Uprn : any
}

export class SearchResultDescription {
  public title: string;
  public subtitle: string;
  constructor() {}
}

export enum SearchType {
  Product = -1,
  Location = -2,
  None = 0,
  Address = 1,
  Postcode = 2,
  NationalGrid = 4,
  Marine = 8,
  Boundaries = 16,
  Gazeteer = 32,
  Products = 64,
  Plans = 63,
  Mapshop = Address | Postcode | NationalGrid | Marine | Boundaries | Gazeteer,
  PlansAhead = Address | Gazeteer
}

export enum GeometryType {
  POINT = 'Point',
  LINE_STRING = 'LineString',
  LINEAR_RING = 'LinearRing',
  POLYGON = 'Polygon',
  MULTI_POINT = 'MultiPoint',
  MULTI_LINE_STRING = 'MultiLineString',
  MULTI_POLYGON = 'MultiPolygon',
  GEOMETRY_COLLECTION = 'GeometryCollection',
  CIRCLE = 'Circle',
}


export interface IWms {
  buffer: number;
  crs: string;
  isBaseLayer: boolean;
  layer: string;
  name: string;
  scale: { min: number, max: number };
  style: any;
  transparent: boolean;
  url: string;
  apiKey : string;
  requireAuth : boolean;
}

export enum ApiKeyMap{
  Mapshop_mm = 'NWY234PZSPPSEL9L',
  Mapshop_nl = '6Q8Z8UQ4THEABC5C'
}

export enum PlanColorType {
  BlackWhite,
  Colour
}

export enum PlanOrientation{
  Portrait,
  Landscape
}

export enum PlansProductId{
  ColorLocationPlan = 2601,
  BWLocationPlan = 2602,
  ColorBlockPlan = 2603,
  BWBlockPlan = 2604,
  ColorLocationA3 = 2613,
  BWLocationA3 = 2614,
  ColorLocationA3SCALE = 2617,
  BWLocationA3SCALE = 2618,
  LargePortrait = 2644,
  COLOR2HA = 2619,
  BW2HA = 2620,
  ColorLocationA42500 = 2641,
  BWLOCAIONA42500 = 2642,
}

export enum PopularPlansIds{
  ColorLocationPlan = 2601,
  BWLocationPlan = 2602,
  ColorBlockPlan = 2603,
  BWBlockPlan = 2604,
}

export enum PlansProducName{
  LocationPlan = 'OS MasterMap A4 1:1,250 4ha PDF Location Plan',
  BlockPlan = 'OS MasterMap A4 1:500 PDF Site/Block Plan',
  LocationA3 = 'OS MasterMap Colour A3 1:1,250 8ha PDF Location Plan',
  BLOCKPLANBW = 'OS MasterMap Black and White A4 1:500 PDF Site/Block Plan',
  LocationPlanColor = 'OS MasterMap Colour A4 1:1,250 4ha PDF Location Plan',
  BlockPlanColor = 'OS MasterMap Colour A4 1:500 PDF Site/Block Plan',
  LocationPlanBW = 'OS MasterMap Black and White A4 1:1,250 4ha PDF Location Plan'
}

export enum ProductScale{
  LocationPlan = '1:1250',
  BlockPlan = '1:500'
}

export enum PlanTemplates{
  SelectTemplate = 0,
  Standard = 1,
  LargePortrit = 3,
  LargePortraitInfoBox = 5,
  Standard2ha = 7
}

export enum FeatureAction{
  DRAWSHAPE,
  DRAWCIRCLE,
  REDO,
  UNDO,
  DELETE,
  PICKFEATURE,
  TEXT,
  DRAWRECTANGLE,
  DRAWLINE
}

// Entire map UI state for saving and restoring. Good for undo and redo.
export interface IMapUndoRedoSnapshot {
  features: Collection<Feature>;
//  baseLayerName: string;
  centre: [number, number];
  zoomLevel: number
}

export interface IBasketCountResponse{
    BasketId: number;
    CompanyID : number,
    ItemCount : number
}

export enum CanBuyStatus {
  CannotBuy = 0,
  Purchasable = 1,
  Quotable = 2
}

export interface IPrice {
  actual?: any,
  canBuy: CanBuyStatus,
  message: string,
  price: string,
  prodid: number
}

export interface IProductPrices {
  PricePerProduct : boolean;
  TotalPrice : string;
  Prices: Array<IPrice>
}

export interface ISavePlanInput{
      TemplateId : number,
      Guid : string,
      WebChannel : any,
      SendEmail : boolean,
      PlanJson : any,
      Product : number,
      PlanId?:number
      ShapeId?: number 
}

export interface IPlanJson {
  Title: string,
  Notes: string,
  UPRN? : any,
  TitleNumber? : any,
  LayerType? : any,
  HmlrPolygon: {
    Wkt: Array<any>,
    HmlrPolygonDisplay: boolean,
    Colour: string,
    Stroke: number
  },
  ShapePolygon?: [{
    Wkt: any,
    Colour: string,
    Stroke: number
  }],//array of features obtained from map
  Extent?: any,
  ExtentWkt? : any,
  }

  //object to hole all the properties of the plan including product Id
  export interface IPlanObject{
  Title?: string,
  Notes?: string,
  UPRN? : any,
  TitleNumber? : any,
  LayerType? : any,
  HmlrPolygon?: {
    Wkt: Array<any>,
    HmlrPolygonDisplay: boolean,
    Colour: string,
    Stroke: number
  },
  ShapePolygon?: [{
    Wkt: any,
    Colour: string,
    Stroke: number
  }],//array of features obtained from map
  Extent?: any,
  ExtentWkt? : any,
  productId ? :number,
  scale?:ProductScale,
  paperSize?: string,
  orientation?:PlanOrientation,
  colourType? : PlanColorType,
  template : PlanTemplates 
  }

  export interface ITemplate{
  Css : string,
  Name : string,
  TemplateHtml : string,
  TemplateId : string,
  }

  export enum TemplateName{
    'Standard - Portrait' = 1,
    'Large - Portrait' = 3,
    'Standard -Location' = 0,
    'Large Info Box - Portrait' = 5,
    'Standard 2ha - Portrait' = 7
  }

    export const ErrorMsg = 'We have been unable to complete your request. Please try again, or contact Customer Support at customersupport@emapsite.com for further assistance.'

  export enum BasketListStatus {
      Init,           // Before anything has happened
      Refreshing,     // During refreshment
      Complete,       // After completion (and with results to display)
      Empty,          // After completion (and no results to display)
      Error           // Something went horribly wrong
  }

  export interface IBasket {
    BasketItems : Array<IBasketItem>,
    BasketSubTotal:number,
    BasketTotal:number,
    VAT:number
}

export interface IBasketItem{
    BasketitemID: number,
    ProductFormats:Array<string>,
    ProductID: number,
    ProductImg:string,
    ProductName: string,
    ProductPrice: number
}

export enum Shape_Type {
  Rectangle,
  Polygon,
  LineString,
  Circle,
  Point
}