<div class="d-flex  main-content" style="flex-wrap:wrap;">
    <div class="search-panel w-100 w-md-35 w-lg-25" id="stepsPanel">
        <!-- panel -->
        <div id="edit-panel" >
          <div class="accordion" id="accordionSteps">
            <div class="accordion-item" aria-expanded="true" >
              <h2 class="accordion-header" id="headingOne">
                <button #searchDivBtn  class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" [hidden]="this.isPurchased">
                  <h5>1. Search location </h5>
                  <fa-icon *ngIf="this.locationSelected" [size]="'2x'" class="accordion-title-img" [icon]="faCheckCircle"></fa-icon>
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                data-bs-parent="#accordionSteps">
                <div class="accordion-body p-xl-2">
                  <div id="search-box" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true"
                    title="Search location first">
              
                    <input type="search" #searchTextBox id="site_search" [(ngModel)]="searchText"
                      placeholder="Enter postcode or title number" (keydown)="searchBox_keydown($event)"
                      (search)="clearSearch()" /><span *ngIf="this.searchClicked && !this.searchResults"
                      class="spinner-border text-info slow search-spinner" role="status"></span>
              
                    <button #startSearchButton class="e-search-box-button search-btn" (click)="doSearch(this.searchText)">
                      <fa-icon [icon]="faSearch"></fa-icon>
                    </button>
                  </div>
                  <div [hidden]="!this.searchClicked || !this.searchResults" tabindex="0">
              
                    <div tabindex="0" class="search-list">
              
                      <ul #resultList class="e-search-results">
                        <div *ngIf="this.searchClicked && this.searchResults && this.searchResults.length === 0">
                          <div class="e-search-result-title">No Search Results</div>
                        </div>
                        <li *ngFor="let searchResult of this.searchResults" class="e-search-result" (click)="showOnMap(searchResult,true)">
                          <div class="e-search-result-title">{{searchResult.Description}}</div>
                          <div class="e-search-result-subtitle">{{searchResult.Category}}</div>
                        </li>
              
                      </ul>
                    </div>
                  </div>
              
                </div>
              </div>
            </div>
            <div class="accordion-item" aria-expanded="false" [ngClass]="getDisabledClass()">
              <h2 class="accordion-header" id="headingTwo">
                <button #selectPlanDivBtn class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" (click)="selectPlanStep()" [hidden]="this.isPurchased">
                  <h5>2. Select your Plan </h5>
                  <fa-icon *ngIf="this.planSelected" [size]="'2x'" class="accordion-title-img" [icon]="faCheckCircle"></fa-icon>
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionSteps">
                <div class="accordion-body p-xl-2">
                  <!-- -->
                  <div >
                    <p>
                    <span class="form-switch m-2 ">
                      <input class="form-check-input" style="margin-right:1rem;" type="checkbox" id="flexSwitchCheckDefault" [(ngModel)]="this.drawHMLRpolygon" (change)="toggleHmlrPolygon($event.target.checked)">
                      <label class="form-check-label" for="flexSwitchCheckDefault">Show HMLR polygon</label>
                  </span></p>
                 
                    <div id="popularPlans" >
                    <h3 style="margin-left:0.5rem;">Most Popular Plans</h3>
                    <div class="m-2" style="font-size:0.8rem;" >
                    <div class="form-check d-flex" style="flex-wrap:wrap">
                      <div style="min-width:150px;width:80%;padding-right:5px;">
                      <input class="form-check-input" type="checkbox" [value]="PlanSProductName.LocationPlan" id="flexCheckDefault" [checked]="this.productIdsArray.includes(PopularPlansIds.ColorLocationPlan) || this.productIdsArray.includes(PopularPlansIds.BWLocationPlan)"  (change)="setProductsArray($event.target)">
                      <label class="form-check-label" for="flexCheckDefault">
                       <!-- A4 Location Plan 4ha 1:1250 -->
                       {{PlanSProductName.LocationPlan}}
                      </label>
                     </div>
                      <div style="float:right;"><b>{{getProductPrice(false)}}</b></div>
                    </div>
                    
                    <div class="form-check d-flex" style="flex-wrap:wrap">
                      <div style="min-width:150px;width:80%;padding-right:5px;">
                      <input class="form-check-input" type="checkbox" [value]="PlanSProductName.BlockPlan" id="flexCheckChecked" (change)="setProductsArray($event.target)" >
                      <label class="form-check-label" for="flexCheckChecked">
                          <!-- A4 Location Plan 4ha 1:500 -->
                          {{PlanSProductName.BlockPlan}}
                      </label>
                    </div>
                      <div style="float:right;"><b>{{getProductPrice(true)}}</b></div>
                    </div>
                   
                    <br/>
                    <div class="d-flex" *ngIf="!this.customPlanSelected">
                    <div class="col-6 form-check">
                      <input class="form-check-input" type="radio" name="colorPopular" id="colorRadioPopular" [value]="PlanColorType.Colour" [(ngModel)] = "this.planType"  (change)="resetProductsArray()">
                      <label class="form-check-label" for="colorRadioPopular">
                        Colour
                      </label>
                    </div>
                    <div class="col-6 flex-end form-check">
                      <input class="form-check-input" type="radio" name="colorPopular" id="bwRadioPopular" [value]="PlanColorType.BlackWhite" [(ngModel)] = "this.planType"  (change)="resetProductsArray()">
                      <label class="form-check-label" for="bwRadioPopular">
                        Black & White
                      </label>
                    </div>
                  </div>
                  </div>
                </div>
                  <!-- <br> -->
                  <div id="customPlans" >
                  <h3 class="ml-2 mb-4">Custom Plans</h3>
                  <div class="m-2">
                    <select name="paper-size" class="form-select my-2" [(ngModel)] = "templateSelection" (change)="setPaperSize(true)" ><span *ngIf="this.templateChanged"
                      class="spinner-border text-info slow search-spinner" role="status"></span>
                      <option value="0" disabled>Select template</option>
                      <option  value="1" class="form-option" selected="true">Standard - Portrait</option>
                      <option  value="7" class="form-option" >Standard 2ha - Portrait</option>
                      <option  value="3" class="form-option" >Large - Portrait</option>
                      <option  value="5" class="form-option" >Large Info Box - Portrait</option>
                    </select>
                 
                  <select name="paper-size" class="form-select my-2" [(ngModel)]="paperSize" (change)="setPaperSize(false,false)" [disabled]="this.templateChanged">
                    <option value="" disabled hidden>Select paper size</option>
                    <option value="A4" class="form-option" >A4</option>
                    <option value="A3" class="form-option" [hidden]="this.templateSelection == PlanTemplates.Standard2ha">A3</option>
                  </select>
                                   
                  <select name="paper-size" class="form-select my-2" [(ngModel)] = "scaleSelection" (change)="setPaperSize(false,true)" [disabled]="this.templateChanged">
                    <option  value="" disabled hidden>Select scale</option>
                    <option  value="1:500" class="form-option" [hidden]="this.templateSelection == PlanTemplates.Standard2ha" >1:500 </option>
                    <option  value="1:1250" class="form-option" selected>1:1250</option>
                    <option  value="1:2500" class="form-option" [hidden]="this.templateSelection == PlanTemplates.Standard2ha" >1:2500 </option>
                  </select>
                  
                  <br/>
                  <div class="d-flex" *ngIf="this.customPlanSelected">
                  <div class="col-6 form-check">
                    <input class="form-check-input" type="radio" name="colorCustom" id="colorRadioCustom" [value]="PlanColorType.Colour" [(ngModel)] = "this.customPlanType"  (change)="resetCustomProductsArray()" >
                    <label class="form-check-label" for="colorRadioCustom">
                      Colour
                    </label>
                  </div>
                  <div class="col-6 flex-end form-check">
                    <input class="form-check-input" type="radio" name="colorCustom" id="bwRadioCustom" [value]="PlanColorType.BlackWhite" [(ngModel)] = "this.customPlanType"  (change)="resetCustomProductsArray()">
                    <label class="form-check-label" for="bwRadioCustom">
                      Black & White
                    </label>
                  </div>
                </div>
                  <div class="form-check mt-4 d-flex" style="flex-wrap:wrap" *ngIf="this.customPlanSelected">
                    <div style="min-width:150px;width:80%">
                    <input class="form-check-input" type="checkbox" [value]="PlanSProductName.LocationPlan" id="customPlanCheckbox" checked (change)="setCustomProduct($event.target.checked)" disabled>
                    <label class="form-check-label" for="customPlanCheckbox">
                     <!-- A4 Location Plan 4ha 1:1250 -->
                     {{paperSize}} {{scaleSelection?scaleSelection:'1:1250'}} {{TemplateName[templateSelection]}}
                    </label>
                    <div style="float:right;width:20%;color:gray;padding-top: 2px;" >{{getSelectedProductPrice(this.productsArray[0])}}</div>
                   </div>
                   </div>
                  </div>
                  </div>
                  <span class="text-center" style="width: 100%;display: block;">
                  <button class="btn btn-green mx-2 mx-lg-4 mb-4 float-end float-sm-none" (click)="continueToNextStep()" [disabled]="!this.productIdsArray.length" >
                      Continue to next step
                  </button>
                </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item" aria-expanded="false" [ngClass]="getDisabledClass()">
              <h2 class="accordion-header" id="headingTwo">
                <button #editPlanDivBtn class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo" (click)="continueToNextStep()">
                  <h5>3. Edit your Plan </h5>
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionSteps">
                <div class="accordion-body p-xl-2">
                 <div class="m-4" >
                  <div >
                      <div >
                        <p>
                        <span class="form-switch">
                          <input class="form-check-input" style="margin-right:1rem;" type="checkbox" id="flexSwitchCheckDefault" [(ngModel)]="this.drawHMLRpolygon" (change)="toggleHmlrPolygon($event.target.checked)">
                          <label class="form-check-label" for="flexSwitchCheckDefault">Show HMLR polygon</label>
                      </span>
                    </p>
                    
                    <p  [class]="!this.stopAnimation ? 'pulse-border':''">
                      <span class="form-switch my-2" data-toggle="tooltip" data-placement="top" title="Snap to point allows you to 'snap' to the features on the map.">
                        <input class="form-check-input" style="margin-right:1rem;" type="checkbox" id="flexSwitchCheckDefault" [(ngModel)]="this.enableSnapInteraction" (change)="toggleSnapInteraction($event.target.checked)">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Enable Snap to Point</label>
                      </span>
                      <br />
                      <span class="form-switch my-2 " [hidden]="!this.enableSnapInteraction">
                        <input class="form-check-input mx-2" style="margin-right:1rem;" type="checkbox" id="flexSwitchCheckDefault" [(ngModel)]="this.showSnapPoints" (change)="toggleSnapPoints($event.target.checked)">
                        <label class="form-check-label" for="flexSwitchCheckDefault">Show Points</label>
                      </span>
                   
                  </p>

                          <b>Drawing tools : </b>
                          <div id="toolbar" class="toolbar">
                              <img class="tool-img" src="../../assets/images/ui/tools/polygon.svg" alt="Draw Polygon toolbar" title="Draw polygon" (click)="featureAction(featureActionType.DRAWSHAPE,$event.target)"/> 
                              <img class="tool-img" src="../../assets/images/ui/tools/rectangle.svg" alt="Draw Rectangle toolbar" title="Draw Rectangle" (click)="featureAction(featureActionType.DRAWRECTANGLE,$event.target)"/> 
                              <img class="tool-img" id="pickfeature_btn" src="../../assets/images/ui/tools/pick_feature.svg" alt="Site selection toolbar" title="Pick Feature: Once you have selected a feature, you can hold down the Ctrl button (Command/Cmd ⌘ on Mac) to select and combine multiple features." (click)="featureAction(featureActionType.PICKFEATURE,$event.target)" tooltip tooltipText="" tooltipTitle="PickFeature"/>
                              <img class="tool-img" src="../../assets/images/ui/tools/circle.svg" alt="Draw circle toolbar" title="Draw circle" (click)="featureAction(featureActionType.DRAWCIRCLE,$event.target)"/>
                              <img class="tool-img" src="../../assets/images/ui/tools/line.svg" alt="Draw line toolbar" title="Draw Line" (click)="featureAction(featureActionType.DRAWLINE,$event.target)"/>
                          </div>
                          <div id="toolbar" class="toolbar" style="padding-top: 0;">
                            <img class="tool-img" src="../../assets/images/ui/tools/undo.svg" alt="Undo toolbar" title="Undo" (click)="featureAction(featureActionType.UNDO,$event.target)" />
                              <img class="tool-img" src="../../assets/images/ui/tools/redo.svg" alt="Redo toolbar" title="Redo" (click)="featureAction(featureActionType.REDO,$event.target)" />
                              <img class="tool-img" src="../../assets/images/ui/tools/delete.svg" alt="Delete" title="Delete" (click)="featureAction(featureActionType.DELETE,$event.target)" />
                              <img class="tool-img" src="../../assets/images/ui/tools/text.svg" alt="Add text" title="Once you have selected this tool, click on the part of the map where you wish the text to start, then type into the text field that appears" (click)="featureAction(featureActionType.TEXT,$event.target)" />
                          </div>
                         
                      </div>
                      <div style="margin-bottom:1rem;">
                          <b>Colour Selection : </b>
                          <span id="colourBar" class="toolbar" *ngIf="!this.disableStyle">
                            <input type="color" id="head" name="head" class="btn-red" [value]="color" (change)="setPolygonColor($event.target.value)" style="vertical-align: middle;" [disabled]="this.disableStyle">
                          </span>
                      </div>
                      <div>
                        <b>Line Thickness : &nbsp;&nbsp;&nbsp;&nbsp;</b>
                        <select id="strokeSelect" (change)="setSelectedStroke($event.target)" [disabled]="this.disableStyle">
                        <option>1</option>
                        <option >2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        </select>
                      </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- panel -->
      <!-- CAD div-->
      <div class="p-4 bg-white cad-div d-none d-lg-block" >
        <div>
          
          <img src="assets\images\ui\MasterMap DWG Standard.jpg" style="width:100%;">
        </div>
        <br>
        <p>Looking for CAD Plan in other formats including DWG and SHP? You can find this in our Mapshop....</p>
        <div class="text-center"><button class="btn btn-primary m-2" (click)="goToMapshop()">
          Go to Mapshop
       </button></div>
      </div >
        <span class="text-center">
          <button class="btn btn-classic m-4" (click)="goToFaqs()">
              Need Help? - Go To FAQs
          </button>
        </span>
    </div>
   
      <div class="envelop w-d-md-100">
      <!-- <div class="map-panel" id="mapPanel" style="flex: 1;min-width:720px;"> -->
      <div class="map-panel" id="mapPanel" >
      <span style="width:100%">
      <div *ngIf="!dontShowPlansMsg"class="d-flex justify-content-between alert alert-warning justify-space-between" style="padding:0.5rem;margin-top:1rem;"><div><b>Please Note : </b>This template is an illustration only and not to scale or proportion. The final pdf will be to scale.</div>
         <div style="width: 10%;"> <button type="button" class="btn-close float-end" data-bs-dismiss="alert" aria-label="Close" (click)="setCookieForDisclaimer()" ></button></div></div>
      <ngx-dynamic-hooks [content]="templateHtml" [options]="templateOptions" (componentsLoaded)="componentLoaded($event)" ></ngx-dynamic-hooks>
      <!-- <button class="btn-expand" (click)="getFullScreen()" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" title="Fullscreen"> <fa-icon [icon]="faExpand" ></fa-icon></button> -->
      <div class="measurement-box" [hidden]="!this.showMeasurements">
        <div class="e-info-panel" >
          <div class="measurement-title">&nbsp;Measurements
          </div>
          <div class="measurement-content">
                  <div class="measurement-row">
                      <div class="measurement-cell"></div>
                      <div class="e-info-panel-copy">
                          <button class="btn btn-sm-primary float-end mb-2 btn-copy" name="Copy" (click)="this.copyLink()" >Copy
                         </button>
                      </div>
                  </div>
             
              <div class="measurement-row">
                  <div class="measurement-name">Area: </div><div class="measurement-value" [innerHTML]="getFormattedArea()"></div>
              </div>
              <div class="measurement-row">
                  <div class="measurement-name">Extent Width: </div><div class="measurement-value" [innerHTML]="getFormattedWidth()"></div>
              </div>
              <div class="measurement-row">
                  <div class="measurement-name">Extent Height: </div><div class="measurement-value" [innerHTML]="getFormattedHeight()"></div>
              </div>
              <div class="measurement-row">
                  <div class="measurement-name">Perimeter: </div><div class="measurement-value" [innerHTML]="getFormattedPerimeter()"></div>            
              </div>

          </div>
      </div>
      </div>
    </span>     
    </div>
    <div class="order-panel " id="orderPanel" > 
        <div class="d-flex flex-xl-column" >
        <div class="card order-card square" [hidden]="this.editMode" >
        
          <div  class="p-2 p-lg-4">
            <span><h6>Selected Plans  </h6></span>
            <!--list of plans selected-->
           <div *ngFor="let product of this.productsArray" >
             <div class="py-2"> {{product}} <span class="float-end"><b>{{getSelectedProductPrice(product)}}</b></span></div>
            </div>
            <span class="p-2">
              <h6>Total : <span class="float-end">{{getTotal()|currency:'GBP':'symbol':'1.2-2'}}</span></h6>
            </span>

          </div>
       
        </div>
        <div class="flex-container">
          <div>
            <span class="text-center">
              <button class="btn btn-green action-btn" (click)="addToBasket()" [disabled]="!this.productIdsArray.length"
                [hidden]="this.isInBasket || this.isPurchased">
                <span *ngIf="!this.addToBasketClicked"> Add to Basket </span><span *ngIf="this.addToBasketClicked"
                  class="spinner-border text-info slow btn-spinner" role="status"></span>
              </button>
              <button type="button" class="btn btn-teal action-btn" (click)="savePlan(true,false,false,null)"
                [disabled]="!this.productIdsArray.length" [hidden]="this.editMode">
                <span *ngIf="!this.savePlanClicked"> Save Plan </span><span *ngIf="this.savePlanClicked"
                  class="spinner-border text-info slow btn-spinner" role="status"></span>
              </button>
              <button type="button" class="btn btn-teal action-btn" (click)="updatePlan(false)"
                [hidden]="this.isPurchased || !this.editMode">
                <span *ngIf="!this.savePlanClicked"> Update Plan </span><span *ngIf="this.savePlanClicked"
                  class="spinner-border text-info slow btn-spinner" role="status"></span>
              </button>
        
            </span>
            <span class="text-center" [hidden]="!this.isPurchased">
              <button type="button" class="btn btn-green m-4 float-end float-sm-none" (click)="openReprocessModal()">
                Reprocess
              </button>
            </span>
          </div>
          <div class="my-4 mx-xl-4 text-center mb-4">
            <button class="btn btn-classic" (click)="openFeedbackModal()">
              Provide Feedback
            </button>
          </div>
        </div>
         <!------ Feedback btn------->
         
         <!-- <div class="p-4 bg-white cad-div d-block d-lg-none" >
          <div>
            
            <img src="assets\images\ui\MasterMap DWG Standard.jpg" style="width:100%;">
          </div>
          <br>
          <p>Looking for CAD Plan in other formats including DWG and SHP? You can find this in our Mapshop....</p>
          <div class="text-center"><button class="btn btn-primary m-2" (click)="goToMapshop()">
            Go to Mapshop
         </button></div>
        </div > -->
      
    </div>
  </div>
</div>
<div #hmlrConfirmationModal class="modal" style="margin-top:20vh" role="dialog" id="hmlrConfirmationModal" tabindex="-1" aria-labelledby="hmlrModalLabel" [ngStyle]="{'display':showHMLRpopup}" >
  <div class="modal-dialog">
    <div class="modal-content" style="width:120%">
        <div class="modal-body" style="padding:2rem">
          <div class="alert alert-info mx-2" >
           <b> We have added the Land Registry Title Deed polygon for the address you have selected.</b>
          </div>
          <div class="d-flex justify-content-around" >
            <button type="button" class="btn btn-sm btn-delete" (click)="okDeleteModal(false)" >Delete Polygon</button>
            <button type="button" class="btn btn-sm btn-primary" (click)="okDeleteModal(true)" >OK</button>
          </div>
          
      </div>
     
    </div>
  </div>
</div>
<div #basketConfirmationModal class="modal" style="margin-top:5vh" role="dialog" id="basketConfirmationModal" tabindex="-1" aria-labelledby="hmlrModalLabel"  >
  <div class="modal-dialog">
    <div class="modal-content basket-modal-content" >
        <div class="modal-body p-2 p-xl-4">
          <app-basket-confirmation [addToBasketSummaryProducts]="summaryProducts" [addToBasketUpsellProducts]="upsellProducts" [numberOfProducts]="this.productIdsArray.length"></app-basket-confirmation>
        </div>
     
    </div>
  </div>
</div>
<div #savePlanModal class="modal" style="margin-top:20vh" role="dialog" id="savePlanModal" tabindex="-1" aria-labelledby="hmlrModalLabel"  >
  <div class="modal-dialog">
    <div class="modal-content" style="width:120%">
        <div class="modal-body" style="padding:2rem">
          <div class="alert alert-info mx-2" >
           <b> Your plan has been saved in your account and can also be viewed here </b><br>
           <p *ngFor="let plansUrl of this.plansUrls">
            <a [href]="plansUrl" > {{plansUrl}}</a>
           </p>
           
          </div>
          <div class="d-flex justify-content-around" >
            <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal"  >OK</button>
            
          </div>
         
      </div>
     
    </div>
  </div>
</div>
<div #StartupMsgModal class="modal" style="margin-top:20vh" role="dialog" id="StartupMsgModal" tabindex="-1" >
  <div class="modal-dialog">
    <div class="modal-content w-md-120" >
        <div class="modal-body" style="padding:2rem">
          <div class="alert alert-info mx-2 mb-2" >
           <p><b> First you need to find your location or title in search bar on the page... </b></p>
           <br/>
           <img src="assets\images\ui\search-bar.png" alt="search bar image" style="width:100%">
          </div>
          <div class="p-2 float-left">
            <input class="form-check-input" type="checkbox" name="dontShowSetting" (change)="setDontShowStatutupMsg($event.target.checked)">
            <label class="form-check-label mx-2" for="dontShowSetting">Do not show me this again</label>
          </div>
          <div class="d-flex justify-content-around" >
            <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal" (click)="displayToast()" >OK</button>
          </div>
           
         
      </div>
     
    </div>
  </div>
</div>
<div #reprocessModal class="modal" style="margin-top:20vh" role="dialog" id="reprocessModal" tabindex="-1" aria-labelledby="modalLabel"  >
  <div class="modal-dialog">
    <div class="modal-content" style="width:120%">
        <div class="modal-body" style="padding:2rem">
          <div class="alert alert-info mx-2" >
            <p><b>Thank you.</b></p>
            <p><b>Your revised Plan will be processed and emailed to you shortly.</b></p>
           
          </div>
          <div class="d-flex justify-content-around" >
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="goToDashboard()" >Go to Dashboard</button>
            <button type="button" class="btn btn-teal-popup" data-bs-dismiss="modal" (click)="goToHomepage()" >Go to home page</button>
          </div>
         
      </div>
     
    </div>
  </div>
</div>
<div #errorModal class="modal" style="margin-top:20vh" role="dialog" id="errorModal" tabindex="-1" aria-labelledby="modalLabel"  >
  <div class="modal-dialog">
    <div class="modal-content" style="width:120%">
        <div class="modal-body" style="padding:2rem">
          <div class="alert alert-info mx-2" >
            <p><b>Sorry, we are unable to process your request at the moment.</b></p>
            <p><b>If the issue persists please contact us. Thank you </b></p>
           
          </div>
          <div class="d-flex justify-content-around" >
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >OK</button>
          </div>
      </div>
     </div>
  </div>
</div>
<div #warningModal class="modal" style="margin-top:20vh" role="dialog" id="errorModal" tabindex="-1" aria-labelledby="modalLabel"  >
  <div class="modal-dialog">
    <div class="modal-content" style="width:120%">
        <div class="modal-body" style="padding:2rem">
          <div class="alert alert-danger mx-2" >
            <p>Ordnance Survey makes a charge for customers to view detailed OS MasterMap for your plan. To help you assess what you need we cover these costs for up to 8 searches.</p><p>You've done 5 searches now, so you have 3 searches left before you make a purchase.</p>
          </div>
          <div class="d-flex justify-content-around" >
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >OK</button>
          </div>
      </div>
     </div>
  </div>
</div>
<div #blockedModal class="modal" style="margin-top:20vh" role="dialog" id="errorModal" tabindex="-1" aria-labelledby="modalLabel"  >
  <div class="modal-dialog">
    <div class="modal-content" style="width:120%">
        <div class="modal-body" style="padding:2rem">
          <div class="alert alert-danger mx-2" >
            <p>Ordnance Survey makes a charge for customers to view detailed OS MasterMap for your plan.</p>
            <p> To help you assess what you need we cover these costs for up to 8 views. As you have now viewed OS MasterMap 8 times without ordering a plan, your account has been blocked from completing further searches.<p> Please contact our Customer Operations team on 0118 973 6883 or customersupport@emapsite.com so we can help you find the maps you need and unblock your account.</p>
          </div>
          <div class="d-flex justify-content-around" >
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="redirectToHomepage()">OK</button>
          </div>
      </div>
     </div>
  </div>
</div>
<div #legendModal class="modal fade myModal" id="legendModal" tabindex="-1" role="dialog" style="display:none">
  <div class="modal-dialog myModalDialog " role="document">
    <div class="modal-content legend-modal" style="min-height:300px;">
     
      <div class="modal-body" style="padding:20px;">
        <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
        <span class="modal-title">
          <h5>Legend</h5>
          <p>Your legend will appear in your plan.<br><span style="color:teal">Legend will appear on all the selected plans.</span> </p>
        </span>
        
        <!-- <h4>Legend: </h4> -->
        <div class="d-flex " *ngFor="let legend of this.legendArr">
          <div class="legend-point" [style.background]="legend.colour" ></div>
          <input type="text" name="legend-color" class="mx-2 form-input" style="width:300px;" [(ngModel)]="legend.text"><button type="button" class="btn-close" (click)="removeLegend(legend.colour,legend.text)"></button>
        </div>
        <div class="text-center" *ngIf="this.legendArr.length">
          <button type="button" data-bs-dismiss="modal" class="btn btn-primary text-center my-4" (click)="addLegendToPlan()">Save & Close</button>
        </div>
       
      </div>
     
    </div>
  </div>
</div>
<div #logoModal class="modal fade logoModal" id="logoModal" tabindex="-1" role="dialog" style="display:none">
  <div class="modal-dialog myModalDialog " role="document">
    <div class="modal-content" style="min-height:300px;">
     
      <div class="modal-body" style="padding:20px;">
        <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
        <span class="modal-title">
          <h5>Logo</h5>
         <p><span style="color:teal">Logo will appear on all the selected plans.</span> </p>
        </span>
        <div style="height:150px;width:100%; border:2px dashed black;margin-bottom: 0.5rem;border-radius:0.375rem;" class="text-center ">
          <p >Drag and drop file here or </p>
          <div   id="drop_zone" class="drop-zone" (dragleave)="dragLeaveHandler($event);" (dragover)="dragOverHandler($event);" (drop)="dropHandler($event);" style="height:100%"> 
          <p *ngIf="this.logoFile">{{this.logoFile.name}}</p>
          </div>
          
        </div>
        <input type="file" style="width:60%" (change)="setUploadedFile($event.target)">
        <div>
          <span [class]="this.wrongFileType?'mt-2 error-text':'mt-2'" style="font-size:smaller" >Supported formats JPG, PNG, TIF and webP</span>
          <p class="mb-2" style="font-size:smaller">Maximum dimensions 600px*200px</p>
          
          <span><b>Or select one of the saved logos</b></span>
          <div class="list-group list-group-flush" style="max-height:150px;overflow-y:auto;">
           <span *ngFor="let logo of this.logoList" style="display:flex;background-color:lightgray;border-radius:0.375rem;" class="mt-1"> <button type="button" class="list-group-item list-group-item-action"  (click)="setSelectedLogo(logo, $event.target)" style="width:80%;border:none;background-color:lightgrey;border-radius: 0.375rem;"><img [src]="logo" style="height:36px;"></button>
           <button type="button" class="btn btn-white text-center" style="font-size:small;margin:0.5rem;background-color:white;width:80px;" (click)="deleteLogo(logo)">Delete</button>
          </span>
          
          </div>
          <br>
          <div class="text-center">
          <img id="templateLogo" class="emapsite-logo" style="width:unset" src="https://www.emapsite.com/mapshop/images/reports/emapsite-logo.jpg" alt="Emapsite Logo"></div>
        </div>
        <div class="text-center" >
          <button type="button" data-bs-dismiss="modal" class="btn btn-primary text-center mt-4" style="font-size:small" (click)="saveLogo()">Save & Close</button>
        </div>
       
      </div>
     
    </div>
  </div>
</div>
<div #extentMsgModal class="modal" style="margin-top:20vh" role="dialog" id="extentMsgModal" tabindex="-1" aria-labelledby="modalLabel"  >
  <div class="modal-dialog">
    <div class="modal-content" style="width:120%">
        <div class="modal-body" style="padding:2rem">
          <div class="alert alert-info mx-2" >
            <p><b>Your chosen area is now fixed. If you would like to move your site, you will need to return to step 2.</b></p>
            <p><b>You will now be able to zoom in and pan around within your area, if you wish to to draw a polygon.</b></p>          
          </div>
          <div class="p-2 float-left">
            <input class="form-check-input" type="checkbox" name="dontShowExtSetting" (change)="setDontShowExtMsg($event.target.checked)">
            <label class="form-check-label mx-2" for="dontShowExtSetting">Do not show me this again</label>
          </div>
          <div class="d-flex justify-content-around" >
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >OK</button>
          </div>
      </div>
     </div>
  </div>
</div>
<div #feedbackModal class="modal" role="dialog" id="feedbackModal" tabindex="-1"  >
  <div class="modal-dialog">
    <div class="modal-content" >

        <div class="modal-body p-2 p-xl-4">
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close float-end"></button>
          <app-feedback [userCacheObj]="this.userCacheObject" ></app-feedback>
        </div>
     
    </div>
  </div>
</div>
<div #pickFeatureModal class="modal" style="margin-top:20vh" role="dialog" id="extentMsgModal" tabindex="-1" aria-labelledby="modalLabel"  >
  <div class="modal-dialog">
    <div class="modal-content" style="width:120%">
        <div class="modal-body" style="padding:2rem">
          <div class="alert alert-info mx-2" >
            <p>Once you have selected a feature, you can hold down the <b>Ctrl</b> button (Command/Cmd ⌘ on Mac) to select and combine multiple features.</p>
          </div>
          <div class="p-2 float-left">
            <input class="form-check-input" type="checkbox" name="dontShowExtSetting" (change)="setDontShowPickFeatureMsg($event.target.checked)">
            <label class="form-check-label mx-2" for="dontShowExtSetting">Do not show me this again</label>
          </div>
          <div class="d-flex justify-content-around" >
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >OK</button>
          </div>
      </div>
     </div>
  </div>
</div>


    