import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {v1 as uuid} from 'uuid';
//import { IMapUndoRedoSnapshot } from './app.model';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor(
    private _cookieService: CookieService,
    private _httpClient: HttpClient
  ) { }

  public GetMapProviders(guid:string): Observable<any> {
    let params = {};
    let mappingUrl: string = `${environment.baseServiceUrl}`+'wcfMappingservice/Mappingservice.svc/api/GetMappingWithContext';
   //let mappingUrl: string = '/api';
    let apiUsername = 'MappingRestUserName';
      let apiPassword = 'MappingRestPassword';

    // if (this._stateService.getState().user.cacheGUID) {
    //     params['Guid'] = this._stateService.getState().user.cacheGUID;
    //     params['WebChannel'] =  environment.webChannel;
    // }
    params['Guid'] = guid;
    params['WebChannel'] =  environment.webChannel;
    let viewSessionID :string=null;
    // Set ViewSessionID if available in cookies.
    if (this._cookieService.check('ViewSessionID')) {
        viewSessionID =this._cookieService.get('ViewSessionID');
        params['ViewSessionID'] = viewSessionID;
    }
   else{
       //generate a viewsessionid and pass it
       viewSessionID = uuid();
       params['ViewSessionID'] = viewSessionID;

   }
   if (!this._cookieService.check('ViewSessionID')) {
    let oneDayFromNow = new Date();
    oneDayFromNow.setTime(oneDayFromNow.getTime() + 86400000); // Milliseconds in a day
    this._cookieService.set('ViewSessionID', viewSessionID, oneDayFromNow, '/', environment.configurations.appConfig.cookies.domain,true);
}
   let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(mappingUrl,params,httpOptions).pipe(
          map((response: any) => {
            //         // Get the ViewSessionID out of one of the URLS - these should all be the same - if there isn't already a sessionID in a cookie.
                    let layerUrl = response.layers[0].url;
                    let viewSessionID = null;
                    const viewSessionIDParam = 'ViewSessionID=';
                    let viewSessionIDPosition = layerUrl.indexOf(viewSessionIDParam);
                    if(viewSessionIDPosition !== - 1) {
                        let viewSessionIDEnd = layerUrl.indexOf('&', viewSessionIDPosition + 1);
                        if (viewSessionIDEnd) {
                            viewSessionID = layerUrl.substring(viewSessionIDPosition + viewSessionIDParam.length, viewSessionIDEnd);
                        } 
                    }
                 
                    return response;
                  }
         )
         ) 
}

public CreateShapeId(guid:string,wkt:string): Observable<any> {
      
  // Get the product categories from the back end
 
  let getProductCategoriesUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/CreateShapeID`
  let params = {
      Guid: guid,
      crs: 27700,
      wkt: wkt,
      Reason: 'Plans v2 shape storage'
  }
    let apiUsername = 'MappingRestUserName';
    let apiPassword = 'MappingRestPassword';
 
    let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
 
    let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': encodedCredentials
        })
        };
       return this._httpClient.post(getProductCategoriesUrl,params,httpOptions).pipe() 
}

public PickFeature(pickFeatureUrl,point):Observable<any>{
  //&x=473497&y=170546
  pickFeatureUrl = pickFeatureUrl + '&x='+point.x + '&y=' +point.y;
  let apiUsername = 'MappingRestUserName';
  let apiPassword = 'MappingRestPassword';
  let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
  let httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': encodedCredentials
    })
  }
  return this._httpClient.get(pickFeatureUrl,httpOptions).pipe() 
}

}
