import { Injectable } from '@angular/core';
import { Feature } from 'ol';
import { Subject } from 'rxjs';
import Geometry from 'ol/geom/Geometry';
import WKT from 'ol/format/WKT';
import { IMapUndoRedoSnapshot } from './app.model';

@Injectable({
  providedIn: 'root'
})
export class UndoRedoService {

private undoStack: IMapUndoRedoSnapshot[] = [];
private redoStack: IMapUndoRedoSnapshot[] = [];
public undoStatus: Subject<boolean> = new Subject<boolean>();
public redoStatus: Subject<boolean> = new Subject<boolean>();

constructor() {}

public pushCurrentMapState(mapState: IMapUndoRedoSnapshot) {
    this.undoStack.push(mapState);
    this.redoStack = [];
    this.broadcastUndoRedoStatus();
  }


public undo(): IMapUndoRedoSnapshot | null {
    if (this.canUndo()) {
      const currentState = this.undoStack.pop();
      if (currentState) {
        this.redoStack.push(currentState);
        this.broadcastUndoRedoStatus();
        return this.getCurrentShapeState();
      }
    }
    return null;
  }

  public redo(): IMapUndoRedoSnapshot | null {
    if (this.canRedo()) {
      const nextState = this.redoStack.pop();
      if (nextState) {
        this.undoStack.push(nextState);
        this.broadcastUndoRedoStatus();
        return nextState;
      }
    }
    return null;
  }


public canUndo(): boolean {
  return this.undoStack.length > 0;
}

public canRedo(): boolean {
  return this.redoStack.length > 0;
}
private getCurrentShapeState(): IMapUndoRedoSnapshot {
    return this.undoStack[this.undoStack.length - 1];
  }

private broadcastUndoRedoStatus() {
  this.undoStatus.next(this.canUndo());
  this.redoStatus.next(this.canRedo());
}

}