export const environment = {
  production: false,
  appName : 'Plans',
  serverConfigName: "qa",
  serverDomainPrefix: "qa-",
  serverDomain: "emapsite.com",
  baseServiceUrl: "https://qa-ews.emapsite.com/",
  servicesUrl: "https://qa-ews.emapsite.com",
  baseEmapsiteUrl: "https://qa-www.emapsite.com",
  basketUrl:"https://qa-checkout.emapsite.com",
  accountsUrl : "https://qa-accounts.emapsite.com",
  mapshopUrl : "https://qa-mapshop.emapsite.com",
  marineUrl : "https://qa-marine.emapsite.com/",
  loginServiceUrl:"https://qa-login.emapsite.com/register?redirecturl=https://qa-plans.emapsite.com",
  logErrors: true,
  webChannel : 6,
  faqsUrl : 'https://www.emapsite.com/plans-faqs',
  envName: "qa",
  configurations: {
      appConfig: {
          cookies: {
            personID: {
                name: 'PersonID',
                timeout: 10 // days
            },
              guid: {
                  name: "Guid-qa"
              },
              domain: "emapsite.com"
          }
      },
      uiConfig: {
        imagesPath: `https://www.emapsite.com/mapImages/`
    }
  }
};