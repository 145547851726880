import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IProductPrices } from './app.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  environment = environment;

  constructor(
    private _httpClient: HttpClient,
    private _cookieService  :CookieService
  ) { }

  GetProductCategories(guid:string): Observable<any> {
      
    // Get the product categories from the back end
    let getProductCategoriesUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/GetProductCategoriesWithContext`
    let params = {
        Guid: guid,
        WebChannel: environment.webChannel
    }
      let apiUsername = 'MappingRestUserName';
      let apiPassword = 'MappingRestPassword';
   
      let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(getProductCategoriesUrl,params,httpOptions).pipe() 
}

  GetProducts(){

  }

  GetProductPrice(wkt: string, productIDs: number[]): Observable<IProductPrices>{
    return null;
  }

  GetPrices(params): Observable<any> {
    let getProductPricesUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/GetPricesWithContext`
    
    // let params = {
    //     Guid: guid,
    //     Geom: wkt,
    //     ProdIDS: productIDs.join(),
    //     WebChannel: environment.webChannel,
    //     SearchesID : selectedSearch?selectedSearch['SearchesID']:null,
    //     SearchesFoundID : selectedSearch?selectedSearch['SearchesFoundID']:null,
    //     SearchesDescription : selectedSearch?selectedSearch['Description']:null,
    //     SearchesCategory : selectedSearch?selectedSearch['Category']:null
    // }
    let apiUsername = 'MappingRestUserName';
      let apiPassword = 'MappingRestPassword';
   
      let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(getProductPricesUrl,params,httpOptions).pipe() 
}

getProductCode(paperSize:string,scale:string,template : string){
 //return 1234;
}
}
