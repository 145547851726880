import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { IGetUserCacheResponse, ISavePlanInput } from './app.model';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(
    private _cookieService: CookieService,
    private _httpClient: HttpClient
  ) {

   }

   getTemplateHtml(guid,templateId){
    let getTemplateUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/GetTemplate`
   // let getTemplateUrl: string = '/api';
    let params = {
        Guid: guid,
        WebChannel: environment.webChannel,
       TemplateId : templateId
    }
    let apiUsername = 'MappingRestUserName';
      let apiPassword = 'MappingRestPassword';
   
      let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(getTemplateUrl,params,httpOptions).pipe() 
   }

   savePlan(params : ISavePlanInput){
    let getTemplateUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/SavePlan`
    
    
    let apiUsername = 'MappingRestUserName';
      let apiPassword = 'MappingRestPassword';
   
      let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(getTemplateUrl,params,httpOptions).pipe() 
   }

   getSavedPlan(params : any){
    let getTemplateUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/RetrievePlan`
    
    
    let apiUsername = 'MappingRestUserName';
      let apiPassword = 'MappingRestPassword';
   
      let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(getTemplateUrl,params,httpOptions).pipe() 
   }

  reprocessPlan(params : any){
   let url = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/ReprocessPlan`
    
    
    let apiUsername = 'MappingRestUserName';
      let apiPassword = 'MappingRestPassword';
   
      let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(url,params,httpOptions).pipe() 

    }

getLogoList(params){
  let url = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/GetAllPlanLogos`
    
    
    let apiUsername = 'MappingRestUserName';
      let apiPassword = 'MappingRestPassword';
   
      let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(url,params,httpOptions).pipe() 

}

saveLogo(params){
  let url = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/SavePlanLogo`
    
    
    let apiUsername = 'MappingRestUserName';
      let apiPassword = 'MappingRestPassword';
   
      let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(url,params,httpOptions).pipe() 
}

deleteLogo(params){
  let url = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/DeletePlanLogo`
  let apiUsername = 'MappingRestUserName';
    let apiPassword = 'MappingRestPassword';
 
    let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
 
    let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': encodedCredentials
        })
        };
       return this._httpClient.post(url,params,httpOptions).pipe() 
}

getPlansProduct(params){
  let url = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/GetPlanProduct`
  let apiUsername = 'MappingRestUserName';
    let apiPassword = 'MappingRestPassword';
 
    let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
 
    let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': encodedCredentials
        })
        };
       return this._httpClient.post(url,params,httpOptions).pipe() 
}

public SendFeedback(feedback: string, allowContact: boolean = false,user : IGetUserCacheResponse) {
  if (allowContact) {
      feedback += "\n\n=== User allows contact ===";
  }
  else {
      feedback += "\n\n=== User doesn't allow contact ===";
  }
  return this.SendMail(
     {
          Name: user.PersonName,
          Email: user.EmailAddress,
          PersonID: user.PersonId,
          Feedback: feedback
      });
}
public SendMail( emailFields: Object): Observable<any> {
  let sendEmailUrl: string = `${environment.servicesUrl}/wcfNotificationService/NotificationService.svc/api/SendEmail`;
  // Start with the required parameters for send mail and an empty Fields array to be populated.
  let params = {
      Params: [],
      Template: { Id: 182, Version: 1},//id for plan mail template
      Sender: { Id: 147462 }, //sender id for customer support team
      PersonID: emailFields['PersonID'],
      SendInternally: true,
      Recipient : { Id: 147462 } //recipient customer support team
  };
  // Now add the additional fields provided. 
 for (let prop in emailFields) {
      if (emailFields.hasOwnProperty(prop)) {
          params.Params.push({
              Key: prop,
              Value: emailFields[prop]
          });
      }
  }
  let apiUsername = 'MappingRestUserName';
    let apiPassword = 'MappingRestPassword';
 
    let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
 
    let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': encodedCredentials
        })
        };
       return this._httpClient.post(sendEmailUrl,params,httpOptions).pipe(map((response) => {
        return {
            success: true
        };
    })) 
  // Do the post to send the mail. Kind of poetic.
  // return this._httpService.doHttpPostWithCredentials(sendEmailUrl,
  //                                                    params,
  //                                                    ConfigService.environment.configurations.siteConfig.api.contact.apiUsername,
  //                                                    ConfigService.environment.configurations.siteConfig.api.contact.apiPassword).pipe(
  //     map((response) => {
  //         return {
  //             success: true
  //         };
  //     }),
  //     catchError((error) => {
  //         return this._errorService.errorHandler(error, 'Send Email', 'EmailService.SendMail', 'The email could not be sent.');
  //     })
  // );
}
}
