import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BasketListStatus, IBasket } from '../app.model';
import { BasketService } from '../basket.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-basket-preview',
  templateUrl: './basket-preview.component.html',
  styleUrls: ['./basket-preview.component.scss']
})
export class BasketPreviewComponent implements OnInit {
  basketVat: number;
  basketTotal: number;
  basketSubtotal: number;
  context: number;
  basketListStatus: BasketListStatus;
  basketItems = [];
  public BasketListStatus = BasketListStatus;
  private _guid: any;
  basket: IBasket;
  private _basketList: any;
  public imagesPath: string = environment.configurations.uiConfig.imagesPath;
  faTimes = faTimes;
  @Output() public basketUpdated = new EventEmitter<any>();

  constructor( 
    private _basketService : BasketService,
    private _cookieService: CookieService,
    private _route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.basketVat = 10;
    this.basketTotal = 20;
    this.basketSubtotal = 20;
    this.context = 0;
    this.basketListStatus = BasketListStatus.Complete;

    this._route.queryParams.subscribe(params => {
      let guidName = environment.configurations.appConfig.cookies.guid.name;
      this._guid = params.guid ? params.guid : this._cookieService.get(guidName);
      if(this._guid)
      this.getBasketItems();
    });
    
  }
  ngAfterViewInit(){
    
  }

  basketClick(){
    window.location.href = `${environment.basketUrl}`;
  }

  public getBasketItems() {
    this.basketListStatus = BasketListStatus.Refreshing;
           // Get basket items list
            this._basketService.GetBasket(this._guid).subscribe(
                (basketData: IBasket) => {
                    // Check that basket data was found 
                    if (basketData) {
                        this.basket = basketData;
                        this.basketItems = basketData.BasketItems;
                        //if basket items retrieved set in the list
                        if(basketData.BasketItems.length>0){
                           this.basketListStatus = BasketListStatus.Complete;
                           this.basketSubtotal = basketData.BasketSubTotal;
                           this.basketVat = basketData.VAT;
                           this.basketTotal = basketData.BasketTotal;
                           
                        }
                        else{
                            this.basketListStatus = BasketListStatus.Empty;
                        }
                    }
                }
            );
}

deleteBasketItem(itemId){

  this._basketService.DeleteBasketItem(itemId,this._guid).subscribe((basketData: IBasket)=>{
    //update basket
    if(basketData){
      this.basket = basketData;
                        this.basketItems = basketData.BasketItems;
                        //if basket items retrieved set in the list
                        if(basketData.BasketItems.length>0){
                           this.basketListStatus = BasketListStatus.Complete;
                           this.basketSubtotal = basketData.BasketSubTotal;
                           this.basketVat = basketData.VAT;
                           this.basketTotal = basketData.BasketTotal;
                           
                        }
                        else{
                            this.basketListStatus = BasketListStatus.Empty;
                        }
                        this.basketUpdated.emit(basketData.BasketItems.length);
    }
  })
}

}
