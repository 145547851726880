
<div class="e-feedback-form" style="font-size:14px;">
        <form >
        <div style="margin-bottom:1rem;" >
            <img src="..\\..\\..\\..\\assets\\images\\ui\\icons\\check.svg" class="check-icon" > &nbsp;<span style="font-weight:500;font-size:1.5rem;">Added to your basket</span> 
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="float:right" (click)="this.reset()"></button>
        </div>  
        <div style="max-height:200px;overflow-y:scroll">  
        <div class="e-basket-item" *ngFor="let product of this.basketProducts">
            <div class="basket-img-div"  ><img src="{{imagesPath}}{{product.ProductImageUrl}}" class="basket-image" ></div>
           
            <div class="div-item-details" >
                <div class="e-product-name">{{product.ProductName}}</div>
                <div class="e-product-format" >
                    <span class="other-links">
                    <span >{{getArea(product)}}</span><span [hidden]="product.AreaInHA >=1"><sup>2</sup></span>
                    <sup>&nbsp;<fa-icon [icon]="faInfoCircle" tooltip tooltipText="This is the measurement of the area you have selected for this product" tooltipTitle="Area" ></fa-icon></sup>
                    </span>
                   
                  </div>
               
            </div>
           
            <div style="margin-top:1rem;font-weight:500;font-size:1.1rem;width:16%;display:flex;justify-content:center;" ><b>{{product.ProductPrice.toFixed(2)|currency:'GBP':'symbol'}}</b></div>
        </div>
        </div>
        <div style="display:flex;border-bottom:1px solid grey;padding:0.5rem;">
            <div style="width:75%"><a (click)="this.viewFullBasket()" style="cursor:pointer" [hidden]="this.hideLink"><u>View Full Basket</u></a></div>
            <div style="float:right"><span>({{this.addToBasketSummaryProducts.length}} items) &nbsp;<b>{{getTotalPrice()|currency:'GBP':'symbol'}}</b></span></div>
        </div>
    
        <div class="btn-div">
            <button _ngcontent-imh-c28="" label="Back to Mapshop" class="btn btn-secondary" style="margin-right: 1rem;" (click)="this.reset()">Create Another Plan</button>
            <button _ngcontent-imh-c28="" label="Checkout Now" class="btn btn-primary" style="margin-right: 1rem;" (click)="basketClick()">Checkout Now</button>
        </div>
        <div style="padding:0.5rem;">
            <div class="my-2"><span style="font-weight:600;font-size:1.1em;">Other customers have ordered these alternative or complementary products…..</span></div>
            <div class="product-div-container">
                <div class="product-div" *ngFor="let prod of this.addToBasketUpsellProducts">
                    <div class="image-div" >
                       <div> <img src="{{imagesPath}}{{prod.ProductImageUrl}}" class="basket-image-small" ></div>
                        <div class="product-title">
                            <p><span style="font-weight:500;margin-bottom:0;">{{prod.ProductName}}</span></p>
                            
                        </div>
                    </div>
                    <div class="prod-desc">
                        <p><span >{{prod.ProductShortDescription}}</span></p>
                    </div>
                    <div style="justify-content:right;">
                        
                        <button #checkoutButton (click)="selectArea(prod)" class="btn btn-primary">Select Area</button>
                    </div>
                </div>
            
            </div>
        </div>
        
        </form>
</div>

