<!-- <div class="spinner-container" [hidden]="this.basketListStatus !== BasketListStatus.Refreshing">
    <img src="assets/images/ui/spinner.svg" class="spinner"/>
    <div>refreshing basket...</div>
</div> -->
<div class="spinner-container" [hidden]="this.basketListStatus === BasketListStatus.Empty?false:true">
    <span style="text-align:center" >There are no items in your basket</span>
</div>
<div class="spinner-container" [hidden]="this.basketListStatus === BasketListStatus.Error?false:true">
    <span style="text-align:center" >Sorry could not fetch your basket.</span>
</div>
<div [hidden]="this.basketListStatus !== BasketListStatus.Complete">
<div #basketItemList class="basket-list" >
    <div class="e-basket-list" >
        <div class="e-search-status" *ngIf="this.basketListStatus === BasketListStatus.Refreshing">
            <span>Getting basket items ...</span>
          
        </div>
    
        <div class="e-search-status" *ngIf="this.basketListStatus === BasketListStatus.Error">
            <span >Basket items could not be retrieved.</span>
        </div>
        <div class="e-search-status" *ngIf="this.basketListStatus === BasketListStatus.Empty">
            <span >Basket items could not be retrieved.</span> 
        </div>
        <div *ngFor="let basketItem of this.basketItems" class="e-basket-item">
            <div   >
                <img *ngIf="basketItem.ProductImg" src="{{imagesPath}}{{basketItem.ProductImg}}" class="basket-image" >
                <span class="basket-image" *ngIf="!basketItem.ProductImg" [iconSize]="'2x'"
                [infoText]="''" [infoType]="InfoType.Info"></span> 
            </div>
            <div class="basket-product-container" >
                <div class="e-product-name-container">
                    <div class="e-product-name">{{basketItem.ProductName}}</div>
                  <!--  <div class="e-product-format" *ngFor="let format of this.basketItem.ProductFormat">{{format}}</div>
                  -->
                  <div class="e-product-format" *ngFor="let format of this.basketItem.ProductFormats">{{format}}</div>
                </div>
    
                
            </div>
            <div  class="e-product-price" >{{basketItem.ProductPrice.toFixed(2)|currency:'GBP':'symbol'}}</div>
            <div *ngIf="this.context !== 4"><fa-icon [icon]="faTimes" [size]="'lg'" class="delete-icon" (click)="this.deleteBasketItem(basketItem.BasketitemID)" ></fa-icon></div>
        </div>
    
       </div>
</div>
<div class="basket-view-footer">
    <div class="e-checkout">
        <button #checkoutButton class="btn e-button" (click)="this.basketClick()"><span>{{this.context===4?'View Basket':'Checkout'}}</span></button>
    </div>
<div style="width:50%;padding-bottom: 10px;">
    <div #basketSubTotal class="basket-subtotal">
        <div style="width: 50%;">SubTotal : </div><div style="width: 50%;">{{this.basketSubtotal|currency:'GBP':'symbol'}}</div>
    </div>
    <div  class="basket-vat">
        <div style="width: 50%;">VAT : </div><div style="width: 50%;">{{this.basketVat|currency:'GBP':'symbol'}}</div>
    </div>
    
    <div  class="basket-total">
        <div style="width: 50%;">Total : </div><div style="width: 50%;">{{this.basketTotal|currency:'GBP':'symbol'}}</div>
    </div>
</div>

</div>
</div>
