<!--header -->
<div class="toolbar" role="banner">
     <div class="header w-100">
      <nav class="navbar w-100">
          <div class="navbar-header-logo">
          <!-- <img [src]="this.getHeaderLogo()" [alt]="this.getHeaderLogoAlt()" /> -->
          <a href="https://www.emapsite.com"  ><img src="assets/images/logos/emapsite-plans.svg" style="height:50px;"></a>
          </div>

          <div class="navbar-buttons">
              <div #appMenu id="app_menu_button" tabindex="0" class="navbar-link-site-menu" e-tooltip
                  (keyup.escape)="appsHide()" (keyup.enter)="appsClick()" (keyup.space)="appsClick()"
                  (click)="appsClick()" (blur)="appsHide()">
                 
                      <!-- <img src="assets/images/ui/icons/header/Icon-apps.svg"  class="header-icon"> -->
                      <span style="font-family:'Foco-Bold', 'Foco', sans-serif;" class="navbar-link-text"><b>Go to</b></span>
                  <ul id="app_menu" [hidden]="!this.appMenuActive">
                      <li  class="app-menu-item"><span
                              
                              (click)="this.openUrl('https://'+environment.serverDomainPrefix+'mapshop.' + environment.serverDomain)">
                              <img src='assets/images/logos/mapshop_app_icon.svg' class="header-icon"/></span></li>
                      <li  class="app-menu-item"><span
                              
                              (click)="this.openUrl('https://'+environment.serverDomainPrefix+'reports.' + environment.serverDomain)">
                              <img src='assets/images/logos/reports_app_icon.svg' class="header-icon" /></span></li>
                      <li class="app-menu-item"><span 
                        (click)="this.openUrl('https://'+environment.serverDomainPrefix+'contractorlink.' + environment.serverDomain)">
                        <img src='assets/images/logos/contractor_link_app_icon.svg' class="header-icon" /></span></li>
                      <!-- <li class="app-menu-item"><span 
                              (click)="this.openUrl(environment.baseEmapsiteUrl + '/mapshop/ocean.aspx?x=472319&y=172341&zoom=12.999999999999932')">
                              <img src='assets/images/logos/marine_app_icon.svg' class="header-icon"/></span></li> -->
                              <li class="app-menu-item"><span 
                                (click)="this.openUrl(environment.marineUrl)">
                                <img src='assets/images/logos/marine_app_icon.svg' class="header-icon"/></span></li>
                     
                                          
                  </ul>
                 
                      <img src="assets/images/ui/icons/header/down_chevron.svg" class="header-chev-icon" />
              </div>
          </div>
          <div class="spacer"></div>
        <div class="navbar-buttons" [hidden]="this.userNotSignedIn">
                                 
         <div tabindex="0" #userMenu (keyup.escape)="userHide()" (keyup.enter)="userClick()" (keyup.space)="userClick()"                  
                  class="navbar-link" (click)="userClick()" (blur)="userHide()" [hidden]="this.userNotSignedIn" style="border-left:1px solid white;">
                  <img src="assets/images/ui/icons/header/user.svg" class="header-icon" /> 
                 
                      <span
                      class="navbar-link-text">{{userCacheObject.PersonName}}</span>
                  <ul id="user_menu" class="navbar-menu-user" [hidden]="!this.userMenuActive">
                      <li class="navbar-menu-item"
                          (click)="this.openUrl(environment.baseEmapsiteUrl + '/account/AccountSummary.aspx')">
                          <span >
                            <img src="assets/images/ui/icons/header/user.svg" class="header-icon" /> 
                             
                                  Account Dashboard</span>
                      </li>
                     
                      <!-- <li class="navbar-menu-item" (click)="this.openUrl(environment.baseEmapsiteUrl + '/geolink/')">
                          <span >
                            <img src="assets/images/ui/icons/header/geolink.svg" class="header-icon" /> 
                           
                                  Geolink</span></li> -->
                     
                      <li class="navbar-menu-item" (click)="signOutClick()"><span >
                        <img src="assets/images/ui/icons/header/sign_out.svg" class="header-icon" /> 
                      
                                  Sign Out</span></li>
                  </ul>
                  <img src="assets/images/ui/icons/header/down_chevron.svg" class="header-chev-icon" /> 
                 
              </div>
              <div 
                  tabindex="0" #notificationMenu (keyup.escape)="notificationHide()"
                  (keyup.enter)="notificationClick()" (keyup.space)="notificationClick()" class="navbar-link"
                  (click)="notificationClick()" >
                  <span class="navbar-menu-item">
                      <span class="basket-count" id="notification-number"></span>
                      <img src="assets/images/ui/icons/header/notification.svg" class="header-icon" /> 
                      
                          <span class="header-text navbar-link-text">Notifications</span>
                          <img src="assets/images/ui/icons/header/down_chevron.svg" class="header-chev-icon" /> 
                        
                      <div id="notification-window" class="notification-window"
                          [hidden]="!this.notificationMenuActive">
                          <div class="spinner-container">
                              <img src="assets/images/ui/spinner.svg" class="spinner" />
                              <div>Checking your notifications...</div>
                          </div>
                      </div>
                      <div id="notification-modals">
                      </div>
                      <!-- Notifications
                      -->
                  </span>
                  <!-- <span></span> -->
                  
              </div>
              <div tabindex="0" #infoMenu (keyup.escape)="infoHide()" (keyup.enter)="infoClick()"
              (keyup.space)="infoClick()" class="navbar-link" (click)="infoClick()" (blur)="infoHide()">
              <span >
                <!-- <img src="assets/images/icons/Icon-help.svg" class="header-icon" />  -->
               
                      <span class="navbar-link-text"> Help</span>
                  <ul id="info_menu" class="navbar-menu" [hidden]="!this.infoMenuActive">
                      <li class="navbar-menu-item"><span class="purechat-button-expand">
                        <img src="assets/images/ui/icons/header/chat.svg" class="header-icon" /> 
                          Start Web Chat</span></li>
                      <li class="navbar-menu-item"><a href="tel:0118 973 6883">
                        <img src="assets/images/ui/icons/header/phone.svg" class="header-icon" /> 
                             Call on 0118 973 6883</a></li>
                      <li class="navbar-menu-item"><span 
                              (click)="this.openLink(environment.baseEmapsiteUrl + '/plans-faqs')"><img  src="assets/images/ui/icons/header/videos.svg" class="header-icon" >&nbsp; FAQs</span></li>
                      <li class="navbar-menu-item"><span 
                              (click)="this.openLink(environment.baseEmapsiteUrl + '/video-tutorials-' + environment.appName)">
                      
                                  <img src="assets/images/ui/icons/header/videos.svg" class="header-icon" /> 
                                  View our Video
                              Tutorials</span></li>
                      <li class="navbar-menu-item" (click)="feedbackClick()"><span >
                        <img src="assets/images/ui/icons/header/feedback.svg" class="header-icon" /> 
                                  Provide Feedback</span></li>
                      
                  </ul>
                  <img src="assets/images/ui/icons/header/down_chevron.svg" class="header-chev-icon" /> 
                  </span>
          </div>
              
              <div class="navbar-link basket-link" #basketPreview   >
                  <span class="navbar-menu-item" (mouseenter)="this.showBasket()">
                      <span class="basket-count">{{basketCount}}</span>
                      <img src="assets/images/ui/icons/header/basket-icon.svg" class="header-icon" /> 
                       <span class="navbar-link-text">Basket</span>
                  </span>
              </div>
          </div>
          <div [hidden]="!userNotSignedIn" class="navbar-buttons" style="position:absolute;right:2rem;border-left:1px solid white">
           
              <div tabindex="0" #infoMenu (keyup.escape)="infoHide()" (keyup.enter)="infoClick()"
              (keyup.space)="infoClick()" class="navbar-link" (click)="infoClick()" (blur)="infoHide()">
              <span >
                       <!-- <img src="assets/images/ui/icons/header/basket_icon.svg" class="header-icon" /> -->
                      <span class="navbar-link-text" style="font-size:16px"> Help</span>
                  <ul id="info_menu" class="navbar-menu" [hidden]="!this.infoMenuActive" style="width:250px;">
                      <li class="navbar-menu-item"><span class=" purechat-button-expand">
                         <img src="assets/images/ui/icons/header/chat.svg" class="header-icon" />
                                  Start Web Chat</span></li>
                      <li class="navbar-menu-item"><a href="tel:0118 973 6883">
                        <img src="assets/images/ui/icons/header/phone.svg" class="header-icon" />
                                  Call on 0118 973 6883</a></li>
                      <li class="navbar-menu-item"><span 
                              (click)="this.openUrl(environment.baseEmapsiteUrl + '/video-tutorials-' + environment.appName)">
                             <img src="assets/images/ui/icons/header/videos.svg" class="header-icon" />
                                  View our Video
                              Tutorials</span></li>
                      <li class="navbar-menu-item" (click)="feedbackClick()"><span >
                               <img src="assets/images/ui/icons/header/feedback.svg" class="header-icon" />
                                  Provide
                              Feedback</span></li>
                     
                  </ul>
                  <img src="assets/images/ui/icons/header/down_chevron.svg" class="header-chev-icon" />
              </span>
          </div>
          
          </div>
      </nav>
      <div   class="basket-view" [hidden]="!this.showbasket"  (mouseleave)="basketHide()">
        <!-- <app-basket-preview #basketView id="basketView" class="navbar-link" [basketCount]="this._stateService.getState().basket.basketCount" [basketShow]="this.showbasket"></app-basket-preview> -->
        <app-basket-preview #basketView id="basketView" class="navbar-link"></app-basket-preview>
    </div>
  </div>
</div>
<div #feedbackModal class="modal" role="dialog" id="feedbackModal" tabindex="-1"  >
  <div class="modal-dialog">
    <div class="modal-content" >
      
        <div class="modal-body p-2 p-xl-4">
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close float-end"></button>
          <app-feedback [userCacheObj]="this.userCacheObject" ></app-feedback>
        </div>
     
    </div>
  </div>
</div>

<router-outlet (activate)="subscribeToEmmiter($event)" ></router-outlet>