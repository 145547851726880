import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  environment = environment;

  constructor(
    private _httpClient: HttpClient,
    private _cookieService  :CookieService
  ) { }

  GetBasketCount(guid : string) {
    let getBasketCountUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/GetBasketCount`;
    let params = {
        Guid: guid,
        WebChannel: environment.webChannel,
    };
    // return this._httpService.doHttpPostWithCredentials(getBasketCountUrl,
    //                                                    params,
    //                                                    ConfigService.environment.configurations.siteConfig.api.basket.apiUsername,
    //                                                    ConfigService.environment.configurations.siteConfig.api.basket.apiPassword).pipe(shareReplay(),
    //     catchError((error) => {
    //         return this._errorService.errorHandler(error, 'Get Basket Count', 'BasketService.GetBasketCount', 'The count of items in your basket could not be retrieved.');
    //     })
    // )
   
    let apiUsername = 'MappingRestUserName';
    let apiPassword = 'MappingRestPassword';

     let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': encodedCredentials
      })
      };
     return this._httpClient.post(getBasketCountUrl,params,httpOptions).pipe(
      
     )    
} 

addToBasket(guid:string,planIds : any){
  let addToBasketUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/AddToBasketWithContext`;
  let params = {
      Guid: guid,
      WebChannel: environment.webChannel,
      PlanIds : planIds
  };
  let apiUsername = 'MappingRestUserName';
    let apiPassword = 'MappingRestPassword';

     let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': encodedCredentials
      })
      };
     return this._httpClient.post(addToBasketUrl,params,httpOptions).pipe()
}

/*
  Get basket count
  Guid - guid of person
*/
GetBasket(guid: string) {
  let getBasketUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/GetBasket`;
  let params = {
      Guid: guid
  };
  let apiUsername = 'MappingRestUserName';
  let apiPassword = 'MappingRestPassword';
  let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
  let httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': encodedCredentials
    })
    };
  return this._httpClient.post(getBasketUrl,params,httpOptions).pipe()
  
} 

DeleteBasketItem(basketItemId : number,guid) {
  let getBasketUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/DeleteBasketItem`;
  let params = {
      Guid: guid,
      BasketItemID : basketItemId
  };
  let apiUsername = 'MappingRestUserName';
  let apiPassword = 'MappingRestPassword';
  let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
  let httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': encodedCredentials
    })
    };
  return this._httpClient.post(getBasketUrl,params,httpOptions).pipe()
}
}
