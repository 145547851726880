import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { IGetUserCacheResponse, ILoginServiceResponse, ILogUserInResponse, LoginStatus } from './app.model';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private _httpClient: HttpClient,
    private _cookieService  :CookieService
    ) { }

    ngAfterViewInit(){
      //call getSession to check if login is required
      this.getSession().subscribe((response)=>{
        //if response = user logged in nothing to do redirect to map page
        if(response.isLoggedIn){

        }
        //if user not logged in show login challenge
        else{

        }
      })
    }

    // Get cache - check user login and determine status
    GetUserCacheWithChannel(guid: string): Observable<any> {
     // let getUserCacheWithChannelUrl: string = `${environment.baseServiceUrl}`+'/wcfLoginRegistrationService/CachingService.svc/api/GetUserCacheWithChannel';
     let getUserCacheWithChannelUrl: string = `${environment.baseServiceUrl}`+'webApiLoginRegistrationService/api/v1/cache/GetUserCacheWithChannel';
      let params = {
          Guid: guid,
          WebChannel: environment.webChannel
      }
      let apiUsername = 'LoginRestUserName';
      let apiPassword = 'LoginRestPassword';
   
      let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(getUserCacheWithChannelUrl,params,httpOptions).pipe()    
  }
  
    public DeleteUserCache(guid: string): Observable<any> {
      //let deleteUserCacheUrl: string = `${environment.baseServiceUrl}`+'wcfLoginRegistrationService/CachingService.svc/api/DeleteUserCache';
      let deleteUserCacheUrl: string = `${environment.baseServiceUrl}`+'webApiLoginRegistrationService/api/v1/cache/DeleteUserCache';
      let params ='"' + guid + '"';
        let apiUsername = 'LoginRestUserName';
        let apiPassword = 'LoginRestPassword';
   
         let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(deleteUserCacheUrl,params,httpOptions).pipe()    
    }

    public deleteCookie(){
      this._cookieService.delete(environment.configurations.appConfig.cookies.guid.name);
    }

    public UpdateUserCache(cacheObj : any){
     // let updateUserCacheUrl: string = `${environment.baseServiceUrl}`+'wcfLoginRegistrationService/CachingService.svc/api/UpdateCacheWithDescription';
     let updateUserCacheUrl: string = `${environment.baseServiceUrl}`+'webApiLoginRegistrationService/api/v1/cache/UpdateCacheWithDescription';
      let params ={
        "Action":"Updating Project",
        "cache":cacheObj
      };
        let apiUsername = 'LoginRestUserName';
        let apiPassword = 'LoginRestPassword';
   
         let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(updateUserCacheUrl,params,httpOptions).pipe(
          
         )    

    }

    // Log user in
    LogUserIn(email: string, password: string,stayLoggedIn:boolean): Observable<any> {
      let logUserInUrl = `${environment.servicesUrl}/webApiLoginRegistrationService/api/v1/login/LogUserIn`
      let guid;
      if (this._cookieService.check(environment.configurations.appConfig.cookies.guid.name)) {
          // There's a GUID cookie floating about, use it to determine user login status
        guid = this._cookieService.get(environment.configurations.appConfig.cookies.guid.name);
        
      }
      else{
        guid= null;
      }
     
      let params = {
          UserName: email,
          Password: password,
          //SessionID: this._stateService.getState().user.sessionId,
          SessionID : null,
          StayLoggedIn:stayLoggedIn,
          Guid:guid
      }

      let apiUsername = 'LoginRestUserName';
      let apiPassword = 'LoginRestPassword';
 
       let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
 
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': encodedCredentials
        })
        };
      return this._httpClient.post(logUserInUrl,params,httpOptions).pipe()
        }

        public getSession(guid?: string): Observable<ILoginServiceResponse> {
          // Establish the user's login status ... look in cookie for session guid
          if (!guid && this._cookieService.check(environment.configurations.appConfig.cookies.guid.name)) {
              // There's a GUID cookie floating about, use it to determine user login status
              guid = this._cookieService.get(environment.configurations.appConfig.cookies.guid.name);
          }
  
          // If there's a guid, attempt to get the session
          if (guid) {
              return this.GetUserCacheWithChannel(guid).pipe(
                  map((response: IGetUserCacheResponse) => {
                      // Is this a logged in user or a guest (and in the latter case, guest login is allowed)?
                      if (response.IsLoggedOn ) {
                        
                          let oneYearFromNow = new Date();
                          oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
                          this._cookieService.set(environment.configurations.appConfig.cookies.guid.name, response.PersonGuid, oneYearFromNow, '/', 
                                                  environment.configurations.appConfig.cookies.domain,true);
                          this._cookieService.set(environment.configurations.appConfig.cookies.personID.name,response.PersonId? response.PersonId.toString():'', oneYearFromNow, '/',
                                                  environment.configurations.appConfig.cookies.domain,true);
  
                          // Set basket in store if available
                          if (response.BasketId && response.BasketId > 0) {
                              let basketId = response.BasketId;
                           
                          }
  
                          // All done, session created OK
                          return {
                              isSuccess: true,
                              isLoggedIn: true,
                              loginStatus: LoginStatus.LoginSuccessful
                          };
                      }
                      else {
                          return {
                              isSuccess: false,
                              isLoggedIn: false,
                              loginStatus: LoginStatus.LoginNotYetAttempted
                          };
                      }
                  }),
                
              );
          }
          else {
              return of({
                  isSuccess: true,
                  isLoggedIn: false,
                  loginStatus: LoginStatus.LoginNotYetAttempted
              })
          }
      }

      public getSessionFromGuid(guid?: string): Observable<IGetUserCacheResponse> {
        // Establish the user's login status ... look in cookie for session guid
        if (!guid && this._cookieService.check(environment.configurations.appConfig.cookies.guid.name)) {
            // There's a GUID cookie floating about, use it to determine user login status
            guid = this._cookieService.get(environment.configurations.appConfig.cookies.guid.name);
        }

        // If there's a guid, attempt to get the session
        if (guid) {
            return this.GetUserCacheWithChannel(guid).pipe(
                map((response: IGetUserCacheResponse) => {
                    // Is this a logged in user or a guest (and in the latter case, guest login is allowed)?
                    if (response.IsLoggedOn ) {
                      
                        let oneYearFromNow = new Date();
                        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
                        this._cookieService.set(environment.configurations.appConfig.cookies.guid.name, response.PersonGuid, oneYearFromNow, '/', 
                                                environment.configurations.appConfig.cookies.domain,true);
                        this._cookieService.set(environment.configurations.appConfig.cookies.personID.name,response.PersonId? response.PersonId.toString():'', oneYearFromNow, '/',
                                                environment.configurations.appConfig.cookies.domain,true);

                        // Set basket in store if available
                        if (response.BasketId && response.BasketId > 0) {
                            let basketId = response.BasketId;
                          
                        }

                        // All done, session created OK
                        return response;
                    }
                    else {
                        return response;
                    }
                }),
               
            );
        }
        else {
            return of({
              BasketId: null,
              CompanyId: null, 
              CompanyName: null,
              EmailAddress: null,
              ImpersonatorID: null,
              IsImpersonator: false,
              IsLoggedOn: false,
              IsGuest: false,
              PersonGuid: null,
              PersonId: null,
              PersonName: null,
              SessionId: null,
              Membership: null
            })
        }
    }

        
}
