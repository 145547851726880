import { Injectable } from '@angular/core';
import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private featureSubject = new BehaviorSubject<any>(null);
  feature$ = this.featureSubject.asObservable();

  updateFeatureProperty(newValue: any) {
    this.featureSubject.next(newValue);
  }
}
