import { ChangeDetectorRef, Component,ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCheckCircle,faSearch,faExpand } from '@fortawesome/free-solid-svg-icons';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { FeatureAction, ISearchResult, SearchType, PlanColorType, PlansProductId, PlansProducName, ISavePlanInput, ProductScale, IPlanJson, IPlanObject, PlanTemplates, TemplateName, PopularPlansIds } from '../app.model';
import { ProductService } from '../product.service';
import { SearchService } from '../search.service';
import { ViewEncapsulation } from '@angular/core';
import { LoadedComponent } from 'ngx-dynamic-hooks';
import { Toast, Collapse } from 'node_modules/bootstrap/dist/js/bootstrap.esm.min.js'
import { PlansService } from '../plans.service';
import { Modal } from 'node_modules/bootstrap/dist/js/bootstrap.esm.min.js';
import WKT from 'ol/format/WKT';
import { fromExtent } from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import { BasketService } from '../basket.service';
import { LoginService } from '../login.service';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import { getCenter } from 'ol/extent';
import { BasketConfirmationComponent } from '../basket-confirmation/basket-confirmation.component';
import { MappingService } from '../mapping.service';
import { FeatureService } from '../feature.service';
import { debounceTime } from 'rxjs/operators';



@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent implements OnInit {
  public searchText: string;
  public originaltemplate = null;
  faSearch = faSearch;
  faCheckCircle = faCheckCircle;
  faExpand = faExpand;
  public searchResults = null;
  private _guid: string;
  private _wkt: any=[];
  private _productPriceMap: Map<number, any> = new Map<number, any>();
  public searchClicked = false;
  public planSelected = false;
  private _productIds = [];
  public productsArray = [];
  public productIdsArray = [];
 
  public color = '#0000FF';
  public _stroke_width = 2;
  public planType;
  public customPlanType = PlanColorType.Colour;
  public templateHtml;
  public templateOptions;
  featureActionType = FeatureAction;
  public drawHMLRpolygon: boolean;
  public showHMLRpopup = false;
  public uprn: string;
  public titleNumber: string;
  public titleNumbers: any;
  public plansUrls = [];
  public locationSelected = false;
  private _scale = ProductScale.LocationPlan;
  public scaleSelection = '1:1250';
  public templateSelection = PlanTemplates.Standard;
  public customPlanSelected = false;
  private _locationXY ;
  private _ext_wkt : string;
  private _planObject : IPlanObject = {
    'template' : PlanTemplates.Standard 
  };
  public PlanTemplates = PlanTemplates;
  public showMeasurements = false;
  public showSnapPoints = false;


  @ViewChild('selectPlanDiv') _selectPlanDiv: ElementRef;
  @ViewChild('selectPlanDivBtn') _selectPlanDivBtn: ElementRef;
  @ViewChild('editPlanDivBtn') _editPlanDivBtn: ElementRef;
  @ViewChild('editPlanDiv') _editPlanDiv: ElementRef;
  @ViewChild('hmlrConfirmationModal') _hmlrSettingPopup: ElementRef;
  @ViewChild('basketConfirmationModal', { static: true }) _basketSettingPopup: ElementRef;
  @ViewChild('savePlanModal') _savePlanModal: ElementRef;
  @ViewChild('StartupMsgModal') _startupModal: ElementRef;
  @ViewChild('startSearchButton') _searchBtn: ElementRef;
  @ViewChild('mapContainer') _mapContainer: ElementRef;
  @ViewChild('reprocessModal') _reprocessPopup: ElementRef;
  @ViewChild('errorModal') _errorModalPopup : ElementRef;
  @ViewChild('warningModal') _warningModalPopup : ElementRef;
  @ViewChild('blockedModal') _blockedModalPopup : ElementRef;
  @ViewChild('legendModal') _legendPopup : ElementRef;
  @ViewChild('logoModal') _logoPopup : ElementRef;
  @ViewChild('extentMsgModal') _extentMsgPopup : ElementRef;
  @ViewChild('feedbackModal') _feedbackPopup: ElementRef;
  @ViewChild('pickFeatureModal') _pickFeaturePopup : ElementRef;
  @ViewChild(BasketConfirmationComponent) basketConfirmationComponent: BasketConfirmationComponent;
    
  private _mapElement: any;
  private _hmlrModal;
  private _basketModal;
  private _planModal;
  private _startingMsgModal;
  public editMode: boolean;
  private _planId;
  private _planIds;
  private _reprocessModal;
  private _errorModal;
  private _warningModal;
  private _blockedModal;
  private _legendModal;
  private _logoModal;
  PlanColorType = PlanColorType;
  PlanSProductName = PlansProducName;
  ProductScale = ProductScale;
  TemplateName = TemplateName;
  dynamicComponents: LoadedComponent[];
  public dontShowAgain: boolean;
  public dontShowStatutupMsg : boolean;
  public dontshowExtMsg : boolean;
  public title='';
  private _planJson: IPlanJson;
  public paperSize : string = 'A4';
  public legendArr = [];
  public enableSnapInteraction = false;
  @Output() public addedToBasket = new EventEmitter<any>();
  addToBasketClicked: boolean = false;
  savePlanClicked: boolean = false;
  isInBasket: boolean = false;
  isPurchased: boolean = false;
  @Output() public userLoggedIn = new EventEmitter<any>();
  selectedFeatureColor: any;
  legendDivHtml: HTMLDivElement;
  origProductIdsArray: any[];
  origProductsArray: any[];
  logoList: Object;
  logoFile: any;
  logoFileContent: string | ArrayBuffer;
  wrongFileType: boolean = false;
  dontShowPlansMsg: boolean = false;
  link: any;
  notes: string;
  searchedLocation: ISearchResult;
  summaryProducts: any = [];
  upsellProducts: any= [];
  private _extentMsgModal: any;
  private _pickFeatureModal : any;
  public userCacheObject;
  private _feedbackModal: any;
  private _savedFeatures: [{ Wkt: any; Colour: string; Stroke: number; }];
  @Output() public mapLoaded = new EventEmitter<any>();
  templateChanged: boolean = false;
  featureInfo: any;
  disableStyle: any = false;
  PopularPlansIds = PopularPlansIds;
  dontshowPickFeatureMsg: boolean;
  private _pickFeatureSelected = false;
  stopAnimation: boolean;
  constructor(
    private _searchService: SearchService,
    private _cookieService: CookieService,
    private _route: ActivatedRoute,
    private _productService: ProductService,
    private _plansService: PlansService,
    private cdr: ChangeDetectorRef,
    private _basketService: BasketService,
    private _loginService: LoginService,
    private http: HttpClient,
    private _elementRef : ElementRef,
    private _renderer: Renderer2,
    private _mapService : MappingService,
    private _featureService : FeatureService

  ) {
    this._route.queryParams
      .subscribe(params => {
        this.editMode = params.planid ? true : false;
        if (this.editMode) {
          this._planId = params.planid;
        }
      }
      );

      
  }

  ngOnInit(): void {
    this.logoFileContent = `${environment.baseEmapsiteUrl}/mapshop/images/reports/emapsite-logo.jpg`;
    this.planType = PlanColorType.Colour;
    this._planObject.colourType = this.planType;
     //reset custom properties
     this.paperSize = '';
     this.scaleSelection = '';
     this.templateSelection = PlanTemplates.SelectTemplate;

    this._route.queryParams.subscribe(params => {
      let guidName = environment.configurations.appConfig.cookies.guid.name;

      this._guid = params.guid ? params.guid : this._cookieService.get(guidName);

    });
    //check for user logged in with guid
    this._loginService.getSessionFromGuid(this._guid).subscribe((response) => {
      if (response.IsLoggedOn) {
        this.userLoggedIn.emit(response);
        this.userCacheObject = response;
        
      }
    })
    let template;
    if (!this.editMode) {
      const link = this._renderer.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = 'assets/css/templates/StandardPlansTemplate.css';
      this._renderer.appendChild(document.head, link);
      this.link = link;
      let tempId = this.templateSelection?this.templateSelection:1;
      this._plansService.getTemplateHtml(this._guid, tempId).subscribe((response) => {
        //get product code and scalebar image url
         //get product code
        let params = {
          'TemplateId': 1,
          'PaperSize': 'A4',
          'Scale' : '1:1250',
          'Colour': PlanColorType.Colour,
          'Orientation': 'Portrait'
        }
        template = response['TemplateHtml'];
      this._plansService.getPlansProduct(params).subscribe((response)=>{
        let scalebarUrl = response['ScaleBarUrl']
        
        template = template.replace('<!------ScaleBar------>','<img class="scale-img" src="'+scalebarUrl+'">');
        this.originaltemplate = template;
        this.templateHtml = this.createDynamicHtml(template);
        let sub = this.mapLoaded.subscribe(()=>{
          sub.unsubscribe();
          this.templateOptions =
          {
            sanitize: false
          }
          setTimeout(()=>{
            let titleElement = (<HTMLInputElement>document.getElementById('titleText'));
          if(titleElement) {
            titleElement.setAttribute('value',this.title)
          }
          },1000)
        })
      
      })
      },
      error =>{
        this._errorModal.show();
      })
    }
    this.dontShowAgain = false;
  }

  ngAfterViewInit() {
    this._featureService.feature$.subscribe((value) => {
      this.featureInfo = value;
    });

    this.origProductIdsArray = this.productIdsArray;
    this.origProductsArray = this.productsArray;

    //check the cookie to show msg
    let ckie = this._cookieService.get('PlansMsgSeen');
    if(ckie){
      this.dontShowPlansMsg = true;
    }

    //first get products
    this.getProductcategories();

    //hmlr polygon setting popup
    this._hmlrModal = new Modal(this._hmlrSettingPopup.nativeElement);
    this._basketModal = new Modal(this._basketSettingPopup.nativeElement);
    this._planModal = new Modal(this._savePlanModal.nativeElement);
    this._startingMsgModal = new Modal(this._startupModal.nativeElement);
    this._reprocessModal = new Modal(this._reprocessPopup.nativeElement);
    this._errorModal = new Modal(this._errorModalPopup.nativeElement);
    this._warningModal = new Modal(this._warningModalPopup.nativeElement);
    this._blockedModal = new Modal(this._blockedModalPopup.nativeElement);
    this._legendModal = new Modal(this._legendPopup.nativeElement,{
      backdrop: 'static',
     // show:true
    });
    this._logoModal = new Modal(this._logoPopup.nativeElement);
    this._extentMsgModal = new Modal(this._extentMsgPopup.nativeElement);
    this._pickFeatureModal = new Modal(this._pickFeaturePopup.nativeElement);
    let basketModalComp = this._basketModal.componentInstance; 
    this._feedbackModal = new Modal(this._feedbackPopup.nativeElement);
    //eventlistener for show
    this._basketSettingPopup.nativeElement.addEventListener('show.bs.modal', (event)=> {
     this.basketConfirmationComponent.addToBasketSummaryProducts = this.summaryProducts;
     this.basketConfirmationComponent.numberOfProducts = this.productIdsArray.length;
     this.basketConfirmationComponent.showCalled();
     })

     this.basketConfirmationComponent.selectAreaClicked.subscribe((product)=>{
      //get the extent wkt and save shae , then redirect o url with shapeId
      this._mapService.CreateShapeId(this._guid,this._ext_wkt).subscribe((shapeId)=>{
        if(product.RedirectUrl){
          let messageInfoURL = product.RedirectUrl+ '/shapeId/' + shapeId;
          window.location.href =  messageInfoURL;
        }
        else{
          console.log("error in creating shapeId")
        }
    
      })

     })

     this.basketConfirmationComponent.closeClicked.subscribe(()=>{
      //reset products array
      this.productIdsArray = [];
      this.productsArray = [];
      //reset popularproducts and custom products
      this.resetProductsArray();
      this.resetCustomProductsArray();
      //close the basket modal
      this._basketModal.hide();
     })


    // //add eventhandler for notes text box
    //attach onclick event
    document.addEventListener("DOMContentLoaded", function() {
      var notesDiv = document.getElementsByClassName('notes-text');
      if (notesDiv.length > 0) {
        notesDiv[0].addEventListener('click', function() {
        
        });
      }
    });

    

     if (!this.editMode) {
      //check for flaag in localstorage
      let dontShow = window.localStorage.getItem('dontshowstartupMsg');
      if(!dontShow || dontShow !== 'true'){
        this._startingMsgModal.show();
      }
      
      const $modals = $(this._elementRef.nativeElement).find('.myModal');
      $modals.draggable({
        handle: ".modal-body",
        appendTo: "body"
      });
    
     this._scale = ProductScale.LocationPlan
    }
    else {
      //open the step3 panel
      var myCollapse = document.getElementsByClassName('collapse')[2];
      var bsCollapse = new Collapse(myCollapse, {
        toggle: true
      });

      //get plan by Id , edit plan mode
      let params = {
        'Guid': this._guid,
        'PlanId': this._planId
      }
      this.locationSelected = true;
     
      this._plansService.getSavedPlan(params).subscribe(res => {

        //set the plan properties accordingly
        this._planJson = JSON.parse(res['PlanJson']);
        
        this.isInBasket = res['IsInBasket'];
        this.isPurchased = res['IsPurchased'];
     
      this.templateSelection = this._planJson['Template'];
        this.uprn = this._planJson.UPRN;
        this.titleNumber = this._planJson.TitleNumber;
        this.paperSize = this._planJson['PaperSize'];
       this.scaleSelection = this._planJson['Scale'];
      
      for (const key in ProductScale) {
        if (ProductScale[key] === this.scaleSelection) {
          this._scale = ProductScale[key] as ProductScale;
          break;
        }
      }
       var mapTxtArr = this._planJson['TextArray'];
       this.productIdsArray = res['Products'];
       //checking if its a custom plan
       this.customPlanSelected = !Object.values(PopularPlansIds).some(enumValue => this.productIdsArray.includes(enumValue));
       //set layertype selection
       if(this.customPlanSelected){
        this.customPlanType = this._planJson['LayerType']
       }
       else{
        this.planType = this._planJson['LayerType']
       }
       //set plan selected true
        this.planSelected = true;
        let tempId = this.templateSelection?this.templateSelection:1;
        let extent = this._planJson.Extent;
        this._locationXY = getCenter(extent);
       
        this.setSelectedTemplate(this.paperSize,false);
        let sub = this.mapLoaded.subscribe(()=>{
          sub.unsubscribe();
          setTimeout(() => {
            this._mapElement.setCenterFromXY(this._locationXY);
            this.setTitleAndNotes(this._planJson.Title, this._planJson.Notes);
            this.drawHMLRpolygon = this._planJson.HmlrPolygon.HmlrPolygonDisplay;
            this.updatePaperSizeOnTemplate(this.paperSize);
            //set center
            let wkt = new WKT();
           //if hmlrpolygon flag is set then draw hmlrpolygon
            let polygons = this._planJson.HmlrPolygon.Wkt;
            this._wkt = polygons;
            let polygonColor = this._planJson.HmlrPolygon.Colour
            let stroke = this._planJson.HmlrPolygon.Stroke;
           
            //draw polygon
            if (this._planJson.HmlrPolygon.HmlrPolygonDisplay && this._planJson.HmlrPolygon.Wkt.length>0 && this._planJson.HmlrPolygon.Wkt[0].trim() !== '') {
              
              this._mapElement.addHmlrPolygon(polygons, false, extent);
            }
           
            this._mapElement.setCenterfromExtent(extent);
            let center = this._mapElement.getMapState()['centre'];
      
            this.setScale(this.scaleSelection);
            //set map layer
            this.switchBaseLayer(this._planJson.LayerType);
          
            this._mapElement.setMapExtents(extent);
           
            //draw polygon
            let features = this._planJson['ShapePolygon'];
            this._savedFeatures = features;
            if (features)
            this._mapElement.addShapeFeatures(features);
           // this._mapElement.removeDragpanInteraction();

            //set selected logo
            let logoUrl = this._planJson['LogoUrl'];
            this.setSelectedLogoInTemplate(logoUrl);

            //add eventlistener
            //event listener for add legend btn
            const legendBtn = document.getElementById('addLegendBtn');
            if(legendBtn){
            //if there is a legend button add legend div and legend text if there is any
            this.legendArr = this._planJson['Legends'];
            this.addLegendToPlan();
            
            legendBtn.onclick = ()=>{
            //add legends for all features
            this.addLegendsForAll();
              this._legendModal.show();
              //set off the backdrop
            let backdrop =  document.getElementsByClassName('modal-backdrop')[0];
            backdrop.setAttribute('style','display:none')
            }
            }
            const logoBtn = document.getElementById('addLogoBtn');
            if(logoBtn){
            logoBtn.onclick = ()=>{
              this._logoModal.show();
              //fetch logo list
              let params = {
                'Guid' : this._guid,
                'WebChannel' : environment.webChannel
              }
              this._plansService.getLogoList(params).subscribe((response)=>{
                this.logoList = response;
              },
              (error)=>{
                this._errorModal.show();
              })
            }
            }
            this._mapElement.textArray = mapTxtArr;+
            this._mapElement.addTextEditMode(mapTxtArr);
            this._mapElement.showZoomControl();
          }, 1200)
        })
       
      },
      error =>{
        this._errorModal.show();
      })
    }

    //change detector called for expressionchanged_______ error
    this.cdr.detectChanges();
  
  }

  searchBox_keydown($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      this._searchBtn.nativeElement.click();
    }
  }

  doSearch(searchTxt: string) {
    this.searchClicked = true;

    //call search
    this._searchService.Search(SearchType.Plans, searchTxt, this._guid).subscribe((response) => {
      this.searchResults = response;
      //check for flags in the response, if true show warning
      if(response.length){
         if(response[0]['PlansWarning']){
          this._warningModal.show();
          this.searchResults = response;
        }
        else if(response[0]['PlansBlock']){
          this._blockedModal.show();
        }
        else{
          this.searchResults = response;
        }
      }

    },
    error =>{
     this._errorModal.show();
  })
   
  }

  continueToNextStep() {
    this.planSelected = true;
    let setting = sessionStorage.getItem('dontshowextmsg');
    if (!setting || setting === 'false')
      this._extentMsgModal.show();
    this._mapElement.showZoomControl();
    this._editPlanDivBtn.nativeElement.click();
    setTimeout(()=>{
      this.stopAnimation = true;
    },5000)
 }

  selectPlanStep(){
    this.planSelected = false;
    this._mapElement.hideZoomControl();
  }
 
  featureAction(action: FeatureAction, target) {
    //cancel any previous selected action on map element
    this._mapElement.removeAllInteractions();
    //call child mapcomponent's action
    switch (action) {
      case FeatureAction.DELETE: this._mapElement.deleteFeature(true);
        break;
      case FeatureAction.DRAWSHAPE: this._mapElement.drawShape();
        break;
      case FeatureAction.DRAWCIRCLE: this._mapElement.drawCircle(false);
        break;
        case FeatureAction.DRAWRECTANGLE: this._mapElement.drawCircle(true);
        break;
      case FeatureAction.REDO: this._mapElement.redo();
        break;
      case FeatureAction.UNDO: this._mapElement.undo();
        break;
     // case FeatureAction.PICKFEATURE: this._mapElement.addPickFeatureInteraction();
     case FeatureAction.PICKFEATURE: this.selectPickFeature();
        break;
      case FeatureAction.TEXT: this._mapElement.addTextToMap();
      break;
      case FeatureAction.DRAWLINE: this._mapElement.drawShape(true);
      break;

    }
    //set the selected style
    let selectedEl = document.getElementsByClassName('selected');
    if (selectedEl.length > 0) {
      selectedEl[0].classList.toggle('selected');
    }
    target.classList.add('selected');

  }

  showOnMap(resultObj: ISearchResult, fromSearch?: boolean) {
    if (fromSearch) {
      //set the productsselected array with first product
      this.productsArray = [];
      this.productIdsArray = [];
      this.productsArray.push(PlansProducName.LocationPlanColor);
      this.productIdsArray.push(PlansProductId.ColorLocationPlan);
    }
    //set searched location for template switching to set mapping
    this.searchedLocation = resultObj;
    //reset plantype to color
    this.planType = PlanColorType.Colour;
    this.locationSelected = true;
    //toggle steps panel
    this._selectPlanDivBtn.nativeElement.click();
    sessionStorage.setItem("showonmap", "true");

    this._wkt = [];
    // get the uprn and title number and reset html
    this.uprn = resultObj['Uprn'] || resultObj['Uprn'] == 0 ? resultObj['Uprn'] : null;
    this.titleNumbers = resultObj['TitleNumbers'];
    let titleStr = '';
    this.titleNumbers.forEach(title => {
      titleStr = titleStr + ' ' + title['Title'];
      this._wkt.push(title['Wkt'])
    })
    this.titleNumber = titleStr;
    let newTemp = this.originaltemplate.replace('[UPRN]', this.uprn);
    newTemp = newTemp.replace('[TitleNo]', titleStr);
    newTemp = newTemp.replace('Scale:', 'Scale: ' + this._scale);
    let size = this.paperSize ? this.paperSize : 'A4';
    newTemp = newTemp.replace('<h6>Paper Size:<!------PaperSize------></h6>', '<h6>Paper Size: ' + size + '</h6>');
    this.templateHtml = this.createDynamicHtml(newTemp);
    let sub = this.mapLoaded.subscribe(() => {
      sub.unsubscribe();
      this.templateOptions =
      {
        sanitize: false
      }
      //set center first
      let center = [resultObj.X, resultObj.Y];
      this._mapElement.setCenterFromXY(center);
      sessionStorage.setItem("x", resultObj.X.toString());
      sessionStorage.setItem("y", resultObj.Y.toString());
      this._locationXY = [resultObj.X, resultObj.Y];

      setTimeout(() => {
        //set plan title
        this.title = resultObj.Description;
        let notes = this.notes ? this.notes : '';

        this.setTitleAndNotes(resultObj.Description, notes);
        if (this.titleNumbers && this.titleNumbers.length > 0 && this.titleNumbers[0]['Wkt']) {
          //check for polygondrawflag if true addfeature, if dontShowAgain true dont show the popup

          if (!this.dontShowAgain) {
            this._hmlrModal.show();
            this._mapElement.addFeatureAsWkt(resultObj);
          }
          else {
            let drawShape = sessionStorage.getItem('PolygonDrawflag');

            if (drawShape !== "false") {
              this._mapElement.addFeatureAsWkt(resultObj);
              this.drawHMLRpolygon = drawShape === "false" ? false : true;
            }

          }

        }
        //fit the map to extents for  area
        if (this._mapElement.getMap()) {
          this._mapElement.locationSelected = true;
          //check for what plan it is based on template Id
          if (!this.templateSelection || this.templateSelection == PlanTemplates.Standard) {
            let size = this.paperSize ? this.paperSize : 'A4'
            this._mapElement.drawLocationPlanArea(size, this._scale);
          }
          else {

            if (this.templateSelection == PlanTemplates.Standard2ha) {
              this._mapElement.drawStandard2haPlanArea();
            }
            else {
              this._mapElement.drawLargePlanArea(this.paperSize);
            }

          }
        }
        else {
          this._mapElement.resetMap();
          this._mapElement.locationSelected = true;
        }
        this.clearSearch();
        //event listener for add legend btn
        const legendBtn = document.getElementById('addLegendBtn');
        if (legendBtn) {
          legendBtn.onclick = () => {
            //add legends for all features
            this.addLegendsForAll();
            this._legendModal.show();
            //set off the backdrop
            let backdrop = document.getElementsByClassName('modal-backdrop')[0];
            backdrop.setAttribute('style', 'display:none')
          }
        }
        const logoBtn = document.getElementById('addLogoBtn');
        if (logoBtn) {
          logoBtn.onclick = () => {

            this._logoModal.show();
            //fetch logo list
            let params = {
              'Guid': this._guid,
              'WebChannel': environment.webChannel
            }

            this._plansService.getLogoList(params).subscribe((response) => {
              this.logoList = response;
            },
              (error) => {
                this._errorModal.show();
              })

          }
        }
      }, 1000)

    })

  }

  okDeleteModal(ok: boolean) {
    this.dontShowAgain = true;
    this._hmlrModal.toggle();
    sessionStorage.setItem('PolygonDrawflag', String(ok))
    let uprnEle = document.getElementsByClassName("top-text");
    //check for ok or delete, if ok draw polygon
    if (!ok) {
      //delete polygon
      this._mapElement.deleteHmlrPolygon();
      this.drawHMLRpolygon = false;
      uprnEle[0].setAttribute('hidden', 'true');
    }
    else {
      this.drawHMLRpolygon = true;

    }
  }

  clearSearch() {
    this.searchResults = null;
    this.searchClicked = false;
  }

  getProductcategories() {
    this._productIds = [2601, 2602, 2603, 2604,2613,2614];
  }

  //gets prices for all the products listed with the shape drawn on the map
  getPrice(ext?:any) {
    //if prodExtent map is not null, get prices for each extent entry, else getExtent for default product and set in prodExtent map
   
      let plansProdWkt = new Array<any>();
      //get extent from map and call price for each
      this._productIds.forEach((prod) => {
       
       let extent = this._mapElement.getExtentForProduct(prod,this.paperSize,this.scaleSelection);
        let newPolygon = fromExtent(extent);
       
        let newFeature = new Feature({
          geometry: newPolygon,
        });
        var format = new WKT(),
          wkt = format.writeGeometry(newFeature.getGeometry());
        let planProd = {
          'Key': prod,
          'Value': wkt
        }
        plansProdWkt.push(planProd)
      })

      let params = {
        Guid: this._guid,
        Geom: this._wkt.length?this._wkt[0]:this._wkt,
        ProdIDS: this._productIds.join(),
        WebChannel: environment.webChannel,
        PlanID: null,
        PlansProducts: plansProdWkt
      }

      this._productService.GetPrices(params).subscribe((res) => {
        //from the response object get product price map
        res['Prices'].forEach(element => {
          if (element.price == '£0.00') {
            this._productPriceMap.set(element.prodid, element.message);
          }
          else {
            this._productPriceMap.set(element.prodid, element.price);
           
          }
        });
      },
      error =>{
        this._errorModal.show();
      })
   }

  getProductPrice(isBlockPlan: boolean): number {
    if (!isBlockPlan) {
      if (this.planType == PlanColorType.Colour) {
        return this._productPriceMap.get(PlansProductId.ColorLocationPlan)
      }
      else {
        return this._productPriceMap.get(PlansProductId.BWLocationPlan)
      }
    }
    else {
      if (this.planType == PlanColorType.Colour) {
        return this._productPriceMap.get(PlansProductId.ColorBlockPlan)
      }
      else {
         return this._productPriceMap.get(PlansProductId.BWBlockPlan)
      }
    }
  }
  //set the color of the shape drawn
  setPolygonColor(color: string) {
    this.color = color;
     //get the polygon from feature and set its style to color
    this._mapElement.setFeatureColor(color, this._stroke_width);
  }

  createDynamicHtml(template,cssName?:string) {
   
    //replace the legend div to include pencil image
     template = template.replace('<div class="legend-div"><span>Legend</span>&nbsp;<span></span><div id="legend-list"></div></div>','<div class="legend-div"><span>Legend</span>&nbsp;<span><img src="assets\\images\\ui\\icons\\pencil-edit.svg" class="pencil-img" id="addLegendBtn"></span><div id="legend-list"></div></div>');
     template = template.replace('<img class="emapsite-logo"','<span><img src="assets\\images\\ui\\icons\\pencil-edit.svg" class="pencil-img" id="addLogoBtn" style="margin:0.5rem;"><img class="emapsite-logo"')
    var parser = new DOMParser();
    var htmlDoc = parser.parseFromString(template, 'text/html');
    let containerElement = htmlDoc.getElementsByClassName('map')[0];
    if (containerElement) {
      let mapEl = htmlDoc.createElement('app-map');
      containerElement.appendChild(mapEl);
    }
    return htmlDoc.body.innerHTML;
  }
  
  setSelectedStroke(selectElement) {
    this._stroke_width = selectElement.value;
    this._mapElement.setFeatureStroke(selectElement.value, this.color);
  }

  setProductsArray(target) {
    let templateChanged = !(this.templateSelection == PlanTemplates.Standard || this.templateSelection == PlanTemplates.SelectTemplate)
    //reset zoom levels
    this._mapElement.resetZoomLevels();
    //set the papersize on template 
    this.updatePaperSizeOnTemplate('A4')
    //reset the prod arrays first
    this.origProductIdsArray = this.productIdsArray;
    this.origProductsArray = this.productsArray;
    if(this.customPlanSelected){
    this.productIdsArray = [];
    this.productsArray = [];
    }
    
    //uncheck custom product and hide it and reset values
    this.resetCustomSettings();
    if (target.checked) {
      //set template
      if (templateChanged) {
        //get notes and save
        if (document.getElementsByClassName('notes-text').item(0)) {
          let notes = document.getElementsByClassName('notes-text').item(0).innerHTML;
          this.notes = notes
        }
        this.setSelectedTemplate('A4', false);
      }

      
      //check if its color or b&w and set productId accordingly
      if (this.planType == PlanColorType.Colour) {
        let plansName = target.value === PlansProducName.BlockPlan ? 'BlockPlan' : 'LocationPlan';
        let planId = PlansProductId['Color' + plansName];
        this.productIdsArray.push(planId);
        if(target.value === PlansProducName.BlockPlan){
          this.productsArray.push(PlansProducName.BlockPlanColor)
        }
        else{
          this.productsArray.push(PlansProducName.LocationPlanColor)
        }
      }
      else {
        let plansName = target.value === PlansProducName.BlockPlan ? 'BlockPlan' : 'LocationPlan';
        let planId = PlansProductId['BW' + plansName];
        this.productIdsArray.push(planId)
        if(target.value === PlansProducName.BlockPlan){
          this.productsArray.push(PlansProducName.BLOCKPLANBW)
        }
        else{
          this.productsArray.push(PlansProducName.LocationPlanBW)
        }
      }
    }
    else {
     //if its blockplan check for both blockplans and remove, same for location plan
      if(target.value ===PlansProducName.BlockPlan){
        if(this.productsArray.includes(PlansProducName.BLOCKPLANBW))
        {
          let i = this.productsArray.indexOf(PlansProducName.BLOCKPLANBW);
          this.productsArray.splice(i, 1);
          this.productIdsArray.splice(i, 1);
        }
        else if(this.productsArray.includes(PlansProducName.BlockPlanColor)){
          let i = this.productsArray.indexOf(PlansProducName.BlockPlanColor);
          this.productsArray.splice(i, 1);
          this.productIdsArray.splice(i, 1);
          
        }
      }
      else{
        //locationplan
        if(this.productsArray.includes(PlansProducName.LocationPlanColor))
        {
          let i = this.productsArray.indexOf(PlansProducName.LocationPlanColor);
          this.productsArray.splice(i, 1);
          this.productIdsArray.splice(i, 1);
        }
        else if(this.productsArray.includes(PlansProducName.LocationPlanBW)){
          let i = this.productsArray.indexOf(PlansProducName.LocationPlanBW);
          this.productsArray.splice(i, 1);
          this.productIdsArray.splice(i, 1);
        }
      }
     
    }
   
    //set the map to show blockplan area
    if (target.checked) {
      
      if (this.productsArray.length > 1) {
        this._mapElement.blockandLocationSelected = true;
        this._mapElement.onlyBlockPlanSelected = false;
        this._mapElement.removeBlockFeature();
        this._mapElement.drawLocationPlanArea('A4','1:1250');
        this._mapElement.drawBlockPlanArea(false);
        this._scale = ProductScale.LocationPlan;
      }
      else {
        if(this.productsArray.length && (this.productsArray[0]=== PlansProducName.LocationPlanBW || this.productsArray[0]=== PlansProducName.LocationPlanColor)){
          
          this._mapElement.blockandLocationSelected = false;
          this._mapElement.onlyBlockPlanSelected = false;
          this._mapElement.removeBlockFeature();
          this._mapElement.drawLocationPlanArea('A4','1:1250');
          this._scale = ProductScale.LocationPlan;
        }
        else{
          this._mapElement.blockandLocationSelected = false;
          this._mapElement.onlyBlockPlanSelected = true;
          this._mapElement.removeBlockFeature();
          this._mapElement.drawBlockPlanArea(true);
          this._scale = ProductScale.BlockPlan;
        }
       
      }
       //change baselayer of map
    this.switchBaseLayer(this.planType);
    }
    else {
      
      //check for the products array, if it has block plan draw blockplan
      if (this.productsArray.length && (this.productsArray[0] === PlansProducName.BLOCKPLANBW || this.productsArray[0] === PlansProducName.BlockPlanColor )) {
        this._mapElement.blockandLocationSelected = false;
        this._mapElement.onlyBlockPlanSelected = true;
        this._mapElement.removeLocationFeature();
        this._mapElement.drawBlockPlanArea(true);
        this._scale = ProductScale.BlockPlan;
      }
      else{
        this._mapElement.blockandLocationSelected = false;
        this._mapElement.onlyBlockPlanSelected = false;
        this._mapElement.removeBlockFeature();
        this._mapElement.drawLocationPlanArea('A4','1:1250');
        this._scale = ProductScale.LocationPlan;
      }
    }
    //set the scale accordingly
    this.setScale(this._scale); 
   //reset custom properties
    this.paperSize = '';
    this.scaleSelection = '';
    this.templateSelection = PlanTemplates.SelectTemplate;
  }

  resetProductsArray(){
    this.productIdsArray = [];
    //from the inputs get the product id for each 
    //if initial productarray has one element set scale accordingly
    for(let i=0;i<this.productsArray.length;i++){
      let scale = this.productsArray[i] === PlansProducName.BlockPlanColor || this.productsArray[i] === PlansProducName.BLOCKPLANBW ?'1:500':'1:1250';
      //get prodcode from service
      //get product code
      let params = {
        'TemplateId': 1,
        'PaperSize': 'A4',
        'Scale': scale,
        'Colour': this.planType,
        'Orientation': 'Portrait'
      }
     this._plansService.getPlansProduct(params).subscribe((response) => {
        this.productsArray[i] = response['Name'];
        this.productIdsArray[i] = response['ProductId']
      })
    }
    this.origProductIdsArray = this.productIdsArray;
    //change baselayer of map
    this.switchBaseLayer(this.planType);
  }

  resetCustomProductsArray(){
  this.setProductsArrayForCustom(); //dont call before setting extent
  this.switchBaseLayer(this.customPlanType);
  }

  getSelectedProductPrice(productName: PlansProducName) {
    let prodId;
    let planCode;
    let prefix;
    //gets the product price from the price map with the help of productId
    let i = this.productsArray.indexOf(productName);
    prodId = this.productIdsArray[i];
    return this._productPriceMap.get(prodId)
  }

  componentLoaded(components) {
    
    this.dynamicComponents = components;
    if (this.dynamicComponents && this.dynamicComponents[0]) 
    { 
      this._mapElement = this.dynamicComponents[0].componentRef.instance; 
      this._mapElement.actionFinished.subscribe(()=>{
        //deselect draw btn
        let selectedEl = document.getElementsByClassName('selected');
        if (selectedEl.length > 0) {
          selectedEl[0].classList.toggle('selected');
        }
      })
      this._mapElement.updateLegendColor.subscribe((change : any)=>{
        this.updateLegendColor(change);
      })

      this._mapElement.areaDrawn.subscribe((extent)=>{
      //block user from changing settings of custom plan
       setTimeout(()=>{
        this.templateChanged = false;
       },2200)
        //set prod array for custom and get price for custom 
        if(this.customPlanSelected){
        this.setProductsArrayForCustom(extent);
        }
        else{
          //get price for standard products when area updated
            this.getPrice(extent);
        }
      })
      let sub =this._mapElement.mapCreated.subscribe(()=>{
        sub.unsubscribe();
        this.mapLoaded.emit();
        
      })

      this._mapElement.featureSelected.subscribe((featureInfo : any)=>{
       
        this.featureInfo = featureInfo;
        setTimeout(()=>{
          this.showMeasurements = true;
        },500)
      })
      this._mapElement.featureDeselected.subscribe(()=>{
        this.showMeasurements = false;
      })
      //tap event of hmlr selected or deselected
      this._mapElement.hmlrSelected.subscribe((selected)=>{
        this.disableStyle = selected;
      })
    
      this._mapElement.mapMoved.pipe(debounceTime(300)).subscribe(() => {
      //reprice if its a custom plan only as standard is fixed price
      if(this.customPlanSelected)
        this.getCustomProductPrice();
      });
      
    }
  }

  savePlan(sendEmail: boolean, reprocess: boolean, addToBasket?: boolean, planId?: number,) {
   
    if (!addToBasket) {
      this.savePlanClicked = true;
    }
    let title ='';
    let notes = '';
    if(document.getElementById('titleText')){
    title = (<HTMLTextAreaElement>document.getElementById('titleText')).value;
    this.title = title;
    }
    if(document.getElementsByClassName('notes-text').item(0)){
    notes = document.getElementsByClassName('notes-text').item(0).innerHTML;
    this.notes = notes
    notes = notes !== 'Click here to add notes' ? notes : '';
    }
    //get wkt of extent
    var format = new WKT();
    let ext = this._mapElement.getExtentForProduct(this.productIdsArray[0],this.paperSize,this.scaleSelection);
    let newPolygon = fromExtent(ext);
    let ext_wkt = format.writeGeometry(newPolygon);
    this._ext_wkt = ext_wkt;
    let shapeFeatures = this._mapElement.getShapeFeatures();
   
    //create json for save
    let plansJson = {
      
      'Title': title,
      'Notes': notes,
      'UPRN': this.uprn,
      'TitleNumber': this.titleNumber,
      'LayerType': this.customPlanSelected ? this.customPlanType:this.planType,
      'HmlrPolygon': {
        'Wkt': this._wkt,
        'HmlrPolygonDisplay': this.drawHMLRpolygon,
        'Colour': '#FA0000',
        'Stroke': 3
      },
      'ShapePolygon': shapeFeatures,//array of features obtained from map
      'Extent': ext,
      'ExtentWkt': ext_wkt,
      'Legends':this.legendArr,
      'PaperSize':this.paperSize?this.paperSize:'A4',
        'Scale': this.scaleSelection?this.scaleSelection:(this.productIdsArray[0]===PlansProductId.BWBlockPlan || this.productIdsArray[0]===PlansProductId.ColorBlockPlan)?'1:500':'1:1250',
        'Template':this.templateSelection?this.templateSelection:1,
        'Colour': this.customPlanSelected ? this.customPlanType:this.planType,
        'LogoUrl' : this.logoFileContent,
        'Center' : this._locationXY,
        'TextArray' : this._mapElement.getTextArray()
    }
    //get shapeId for the extent
    let param_shapeId;
    this._mapService.CreateShapeId(this._guid,this._ext_wkt).subscribe((shapeId)=>{
      param_shapeId  = shapeId ? shapeId : 0;
     
    //call save plan, when response comes call second save plan, when response comes call basket, or 
    let params: ISavePlanInput = {
      'TemplateId': this.templateSelection?this.templateSelection:1,
      'Guid': this._guid,
      'WebChannel': environment.webChannel,
      'PlanJson': JSON.stringify(plansJson),
      'SendEmail': false,
      'Product': this.productIdsArray[0],
      'PlanId': planId,
      'ShapeId':param_shapeId
    }
    this._plansService.savePlan(params).subscribe((response) => {
      this._planIds = [];
      this.plansUrls = [];
      this.plansUrls.push(response);
      //extract planId from planUrl 
      let urlSegments = this.plansUrls[0].split('=');
      this._planIds.push(Number(urlSegments[1]));

      //call saveplan again if more than one products
      if (this.productIdsArray.length > 1) {
        let ext = this._mapElement.getExtentForProduct(this.productIdsArray[1],this.paperSize,this.scaleSelection);
        ext_wkt = format.writeGeometry(fromExtent(ext));
         //get shapeId for the extent
          let param_shapeId;
          this._mapService.CreateShapeId(this._guid,ext_wkt).subscribe((shapeId)=>{
            param_shapeId  = shapeId ? shapeId : 0;
          
        //call second saveplan
        let plansJson = {
         
          'Title': title,
          'Notes': notes,
          'UPRN': this.uprn,
          'TitleNumber': this.titleNumber,
          'LayerType': this.customPlanSelected ? this.customPlanType:this.planType,
          'HmlrPolygon': {
            'Wkt': this._wkt,
            'HmlrPolygonDisplay': this.drawHMLRpolygon,
            'Colour': '#FA0000',
            'Stroke': 3
          },
          'ShapePolygon': shapeFeatures,//array of features obtained from map
          'Extent': ext,
          'ExtentWkt': ext_wkt,
          'Legends':this.legendArr,
          'PaperSize': this.paperSize?this.paperSize:'A4',
            'Scale':this.scaleSelection?this.scaleSelection:(this.productIdsArray[1]===PlansProductId.BWBlockPlan || this.productIdsArray[1]===PlansProductId.ColorBlockPlan)?'1:500':'1:1250',
            'Template':this.templateSelection?this.templateSelection:1,
            'Colour': this.customPlanSelected ? this.customPlanType:this.planType,
            'LogoUrl' : this.logoFileContent,
            'Center' : this._locationXY,
            'TextArray' : this._mapElement.getTextArray()
        }
        params = {
          'TemplateId': this.templateSelection?this.templateSelection:1,
          'Guid': this._guid,
          'WebChannel': environment.webChannel,
          'PlanJson': JSON.stringify(plansJson),
          'SendEmail': false,
          'Product': this.productIdsArray[1],
          'ShapeId': param_shapeId
        }
        this._plansService.savePlan(params).subscribe((res) => {

          this.plansUrls.push(res);
          //extract planId from planUrl 
          let urlSegments = this.plansUrls[1].split('=');
          this._planIds.push(Number(urlSegments[1]));
          //if(addtobasket) call else show popup
          if (addToBasket) {
            this._basketService.addToBasket(this._guid, this._planIds).subscribe((res) => {
              this.addToBasketClicked = false;
              this.addedToBasket.emit();
              this.summaryProducts = res['AddToBasketSummaryProducts'];
              this.upsellProducts = res['AddToBasketUpsellProducts']; 
              this._basketModal.show();
              // UA code
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({
            'event': "eec.addToCart",
          'ecommerce': {
              'addToCart': {
                'actionField': {'list': "Add to Basket", 'action': "add"},
                'products': this.getSelectedProductListForUA()
              }
            },
            'gtm.uniqueEventId': 44
          })

          //GA code
          window['dataLayer'].push({
            'event': 'add_to_cart',
            'ecommerce': {
                'currency': 'GBP',                              
                'items':  this.getSelectedProductList()       
            }
             })
          })
        }
          else {
            if (reprocess) {
              this.savePlanClicked = false;
              //call reprocess
              let param = {
                'Guid': this._guid,
                'PlanId': parseInt(this._planId)
              }
              this._plansService.reprocessPlan(param).subscribe(response => {
                //show reprocess modal
                this._reprocessModal.show();
                
              })
            }
            else {
              this.savePlanClicked = false;
              this._planModal.show();
            }
          }
        })
        //*
      },
      error =>{
        this._errorModal.show();
      })
      }
      else {
        if (addToBasket) {
          //call basket
          this._basketService.addToBasket(this._guid, this._planIds).subscribe((res) => {
            this.addToBasketClicked = false;
            this.addedToBasket.emit();
            this.summaryProducts = res['AddToBasketSummaryProducts'];
            this.upsellProducts = res['AddToBasketUpsellProducts']; 
            this._basketModal.show();
           
          })
           //UA code
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({
            'event': "eec.addToCart",
          'ecommerce': {
              'addToCart': {
                'actionField': {'list': "Add to Basket", 'action': "add"},
                'products': this.getSelectedProductListForUA()
              }
            },
            'gtm.uniqueEventId': 44
          })

          //GA code
          window['dataLayer'].push({
            'event': 'add_to_cart',
            'ecommerce': {
                'currency': 'GBP',                              
                'items':  this.getSelectedProductList()       
            }
        })
      }
        else {
          if (reprocess) {
            this.savePlanClicked = false;
            //call reprocess
            let param = {
              'Guid': this._guid,
              'PlanId': parseInt(this._planId)
            }
            this._plansService.reprocessPlan(param).subscribe(response => {
              //show reprocess modal
              if(response){
              this._reprocessModal.show();
              
              }
              else{
                this._errorModal.show();
              }
              
            },
            error =>{
              this._errorModal.show();
            })
          
          }
          else {
            this.savePlanClicked = false;
            this._planModal.show();
          }
        }
      }
    },
    error =>{
      this._errorModal.show();
    })
  },
  error =>{
    this._errorModal.show();
  })

  }

  addToBasket() {
      this.savePlan(false, false, true, null);
  }

  goToCheckout() {
    window.location.href = `${environment.basketUrl}`;
  }
  goToHomepage() {
    window.location.href = `${environment.baseEmapsiteUrl}`;
  }
  goToDashboard() {
    window.location.href = `${environment.accountsUrl}`;
  }
  reset() {
    // this._router.navigate(['/mapselector'] );
    window.location.reload();

  }

  goToFaqs() {
    window.open(environment.faqsUrl, '_blank');
  }

  getDisabledClass() {
    return this.locationSelected || this.editMode ? '' : 'ng-disabled';
  }

  displayToast() {
    setTimeout(() => {
      let toastElement = document.getElementById("myToast");
      var myToast = new Toast(toastElement);
      myToast.show();
    }, 100)
  }

  openReprocessModal() {
    this.updatePlan(true);
  }

  getTotal() {
    let total: number = 0;
    if (this.productIdsArray.length > 0) {
      this.productIdsArray.forEach(prodId => {
        let price = String(this._productPriceMap.get(prodId));
        price = price.substring(1);
        total = total + Number(price);
      })
    }
    return total;
  }

  switchBaseLayer(layerType: PlanColorType) {
    //logic to switch base layer
    this._mapElement.setBaseLayer(layerType);
  }

  setScale(scale) {
  
    let scalElement = document.querySelectorAll('.info').item(0)?document.querySelectorAll('.info'):document.querySelectorAll('.scale-info');
    let elements = [];
    let cont = scalElement.item(0);
    elements.push(cont);
    let scaleElement2 = document.querySelectorAll('.info-box-top-right') ?document.querySelectorAll('.info-box-top-right').item(0) : null ;
    if(scaleElement2){
      elements.push(scaleElement2);
    }
    elements.forEach(scalEl => {
      let content = scalEl.innerHTML;
      content = content.replace('<h6>Scale: 1:1250<!------Scale------></h6>', '<h6>Scale: ' + scale + '</h6>');
      content = content.replace('<h6>Scale: 1:1250</h6>', '<h6>Scale: ' + scale + '</h6>');
      content = content.replace('<h6>Scale: 1:500</h6>', '<h6>Scale: ' + scale + '</h6>');
      content = content.replace('<h6>Scale:<!------Scale------></h6>', '<h6>Scale: ' + scale + '</h6>');
      content = content.replace('<strong>Scale:</strong>','<strong>Scale: '+scale+'</strong>');
      content = content.replace('Scale: 1:1250','Scale: '+scale);
      content = content.replace('Scale: 1:2500','Scale: '+scale);
      content = content.replace('Scale: 1:500','Scale: '+scale);
      scalEl.innerHTML = content;
    });
   
    
  }

  setTitleAndNotes(title: string, notes: string) {
    
   let titleElement = (<HTMLTextAreaElement>document.getElementById('titleText'));
   if(titleElement) {
    //titleElement.type = "search";
    titleElement.value = title;
   }
    let notesElement = document.getElementsByClassName('notes-text').item(0);
    if(notesElement && notes!=='')
    notesElement.innerHTML = notes;
  }
   
  updatePlan(reprocess) {
    this.savePlan(false, reprocess, false, this._planId);
  }

  goToOldPlans() {
    window.location.href = 'https://www.emapsite.com/mapshop/plansearch.aspx?jumpTo=plansahead?';
  }

  toggleHmlrPolygon(checked) {
    let uprnEle = document.getElementsByClassName("top-text");
    if (!checked){
      this._mapElement.deleteHmlrPolygon();
      uprnEle[0].setAttribute('hidden','true');
    }
    else {
      this._mapElement.addHMLRPolygonAsWkt(this._wkt);
      uprnEle[0].removeAttribute('hidden');
    }
  }

  toggleSnapInteraction(checked){
    this.enableSnapInteraction = checked;
    //show snap layer if on
    if(checked){
      this._mapElement.showSnapLayer(this.showSnapPoints);
    }
    else{
      //remove snap layer and disable interaction
      this._mapElement.removeSnapLayer();
      this.showSnapPoints = false;
    }
  }

  toggleSnapPoints(checked){
    this._mapElement.setSnapLayerVisible(checked)
  }

  getSelectedProductList(){
    let prodArray = [];
  
    for(let i=0;i<this.productsArray.length;i++){
      let obj = {
        'item_name' : this.productsArray[i],
        'item_id' : this._planIds[i],
        'price' : this.getSelectedProductPrice(this.productsArray[i])
      }
      prodArray.push(obj);
    }
     return prodArray;
  }

  getSelectedProductListForUA(){
    let prodArray = [];
  
    for(let i=0;i<this.productsArray.length;i++){
      let obj = {
        'name' : this.productsArray[i],
        'id' : this._planIds[i],
        'price' : this.getSelectedProductPrice(this.productsArray[i])
      }
      prodArray.push(obj);
    }
     return prodArray;
  }

  getFullScreen(){
    document.getElementById('js-map').requestFullscreen();
   
  }

  redirectToHomepage(){
    window.location.href = "https://www.emapsite.com"
  }

  setPaperSize(changeTemplate?:boolean,changeScale?:boolean){
    //if papersize has changed reset the scale or else, the map keeps sending back to 1:1250
    if(!(changeTemplate || changeScale)){
      this.scaleSelection = '1:1250';
      this.setScale('1:1250');
    }
    else{
      this.paperSize = this.paperSize === ''?'A4':this.paperSize
    }
        
    this._mapElement.customPlanSelected = true;
    //remove any block plan feature
    this._mapElement.removeBlockFeature();
     this.customPlanSelected = true;
   
    if(this.templateSelection == PlanTemplates.Standard || this.templateSelection == PlanTemplates.SelectTemplate ){
      this.templateSelection = PlanTemplates.Standard
      if(this.paperSize === 'A4'){
        this._mapElement.customPlanSelected = false;
      }
    }
    if(!changeScale){
      this.updatePaperSizeOnTemplate(this.paperSize);
    }
   
    this._planObject.paperSize = this.paperSize;
  //  }
  //deselect most popular products checkbox
  let checkBox1 = document.getElementById('flexCheckDefault')
  if(checkBox1.hasAttribute('checked')){
    checkBox1.removeAttribute('checked');
  }
  if($('#flexCheckDefault').is(':checked')){
    $('#flexCheckDefault').prop('checked',false);
  }
  let checkBox2 = document.getElementById('flexCheckChecked')
  if(checkBox2.hasAttribute('checked')){
    checkBox2.removeAttribute('checked')
  }
  if($('#flexCheckChecked').is(':checked')){
    $('#flexCheckChecked').prop('checked',false);
  }

  //uncheck custom product checkbox
  let customCheckBox = document.getElementById('customPlanCheckbox')
  if(customCheckBox){
  if(customCheckBox.hasAttribute('hidden')){
    customCheckBox.removeAttribute('hidden');
  }
  customCheckBox.setAttribute('checked','true');
  }
    if (changeTemplate) {
      //if template has changed first deselect shapes if already slected so that polygon color error is not there
      this._mapElement.deselectShape();
      
      this.templateChanged = true;
      this.scaleSelection = '1:1250';
      this._scale = ProductScale.LocationPlan;
      this.setScale(this.scaleSelection);
      this._planObject.template = this.templateSelection;
      this.customPlanType = PlanColorType.Colour;
      //get notes and save
      if (document.getElementsByClassName('notes-text').item(0)) {
        let notes = document.getElementsByClassName('notes-text').item(0).innerHTML;
        this.notes = notes
      }
      //change the template and draw any polygon 
      this.setSelectedTemplate('A4', changeTemplate);
      let sub = this.mapLoaded.subscribe(()=>{
        sub.unsubscribe();
        setTimeout(() => {
       
          this._mapElement.setCenterFromXY(this._locationXY);
          if (this.templateSelection == PlanTemplates.Standard) {
  
            this._mapElement.customPlanSelected = false;
            this._mapElement.drawLocationPlanArea(this.paperSize, this.scaleSelection);
          }
          else {
            if(this.templateSelection == PlanTemplates.Standard2ha){
              this._mapElement.drawStandard2haPlanArea()
            }
            else{
             
              this._mapElement.drawLargePlanArea('A4')
            }
           
          }
          if(this.drawHMLRpolygon)
          this._mapElement.addHmlrPolygon(this._wkt, false, this._mapElement.getExtent());
        }, 1000)
      })
    
    }
  else {
    //call getproducts to get the scalebarurl
    //get product code
    let params = {
      'TemplateId': this.templateSelection ? this.templateSelection : 1,
      'PaperSize': this.paperSize ? this.paperSize : 'A4',
      'Scale': this.scaleSelection ? this.scaleSelection : '1:1250',
      'Colour': this.customPlanType,
      'Orientation': 'Portrait'
    }

    this._plansService.getPlansProduct(params).subscribe((response) => {
      let scalebarUrl = response['ScaleBarUrl'];
      let scaleImgElement = document.getElementsByClassName('scale-img').item(0);
      scaleImgElement.setAttribute('src', scalebarUrl);
    })
    //if scale or papersize changed then get scalebar
    if (changeScale) {
      
      let scale = this.scaleSelection ? this.scaleSelection : '1:1250';
      let size = this.paperSize?this.paperSize:'A4';
      if (this.templateSelection == PlanTemplates.Standard) {
       
        this._mapElement.drawLocationPlanArea(size,scale);
      }
      else {
       
        this._mapElement.drawLargePlanArea(size,scale);
      }
      this.setScale(this.scaleSelection);

    }
    else {
      //change papersize
      //reset zoom to default for the map to zoomout and show the correct mapping
      this._mapElement.resetZoomLevels();
      if (this.templateSelection == PlanTemplates.Standard) {

        this._mapElement.drawLocationPlanArea(this.paperSize,this.scaleSelection);
      }
      else {
        this._mapElement.drawLargePlanArea(this.paperSize);
      }
      
    }
  }
  //set products and prices for custom plans
   this.switchBaseLayer(this.customPlanType);
  }

  openLegendModal(){
    
    this._legendModal.show()
  }
 
  //method to be called on select of a feature
  addLegend(color,text){
    //add in the array only if it doesn't exists
    if(this.legendArr.length && this.legendArr.length < 5){
    let legend = this.legendArr.find(element=>{
      return element.colour === color;
    })
    if(this.legendArr.indexOf(legend) === -1){
      this.legendArr.push({
        'colour':color,
        'text':text
      })
    }
  }
  else{
    if(!this.legendArr.length){
    //add a row to the array with color and text
    this.legendArr.push({
      'colour':color,
      'text':text
    })
  }
  }
  }

  removeLegend(color,text){
    //find the row with the color and remove it from the array
    let elementToRemove = this.legendArr.find(legend =>{
      return legend.colour === color;
    });
    const index: number = this.legendArr.indexOf(elementToRemove);
   if (index !== -1) {
      this.legendArr.splice(index, 1);
    }
}

addLegendToPlan(){
  //create a div for the legend and add it as innerhtml of template legen-list div
  let legendDiv = document.createElement('div');
  legendDiv.setAttribute('id','legendDiv');
  
  this.legendArr.forEach((legend)=>{
    if(legend.text && legend.text != ''){
   let legendRow = document.createElement('div');
    legendRow.setAttribute('class','d-flex');
    let colorEl = document.createElement('span');
    colorEl.setAttribute('class','legend-point-template');
    colorEl.setAttribute('style','background:'+legend.colour)
    let textEl = document.createElement('span');
    
    textEl.innerHTML = legend.text;
    legendRow.appendChild(colorEl);
    legendRow.appendChild(textEl);
    legendDiv.appendChild(legendRow);
    }
  })
 
  //update template html part
  let list = <HTMLElement>document.getElementById('legend-list');
  if(list)
  { list.innerHTML = legendDiv.innerHTML;
  }
  
}

setCustomProduct(checked:boolean){
  let productCode ;
  switch(this.customPlanType){
    case PlanColorType.BlackWhite : productCode =PlansProductId.ColorLocationA3;
                                    //this.productsArray.push('A3 Location Plan 1:1250');
                                    break;
    case PlanColorType.Colour : productCode = PlansProductId.BWLocationA3;
                               // this.productsArray.push('A3 Location Plan 1:1250');
                                break;
  }
 
  if(checked){
  this.productIdsArray = [];
  this.productsArray = [];
  this.productIdsArray.push(productCode)
  this.productsArray.push('A3 Location Plan 1:1250');
  }
  else{
    this.productIdsArray = this.origProductIdsArray;
    this.productsArray = this.origProductsArray;
  }
}

getPlanColorType(product){
  if(product === 'A3 Location Plan 1:1250'){
    return PlanColorType[this.customPlanType];
  }
  else{
    return PlanColorType[this.planType];
  }

}

resetCustomSettings(){
  this.customPlanSelected = false;
  this._mapElement.customPlanSelected = false;
  let chckBx = document.getElementById('customPlanCheckbox')
  if(chckBx && chckBx.hasAttribute('checked')){
    chckBx.removeAttribute('checked');
  chckBx.setAttribute('hidden','true');
  }
  this.paperSize = '';
  this.templateSelection = PlanTemplates.Standard;
}
addLegendsForAll(){
  //get all features list from mapcomponent
  let colorList = this._mapElement.getAllFeaturesColor();
  //for each feature get its style color
  colorList.forEach((color)=>{
    this.addLegend(color,'');
  })
    //add legend with color
}

updatePaperSizeOnTemplate(paperSize:string){
 
  //set papersize on template
  var element = document.querySelector(".scale-info")?document.querySelector(".scale-info"):document.querySelector(".info");
 
 if(element){
  var content = element.innerHTML;
  var newContent;
  newContent = content.replace("Paper Size:<!------PaperSize------>","Paper Size: "+paperSize);
  if(paperSize === 'A3'){
    
    newContent = newContent.replace("Paper Size: A4", "Paper Size: "+paperSize);
    newContent = newContent.replace("Paper Size:A4", "Paper Size: "+paperSize);
    newContent = newContent.replace(" Paper Size: A4", " Paper Size: "+paperSize);
    newContent = newContent.replace("<strong>Paper Size: A4</strong>","<strong>Paper Size: "+paperSize+"</strong>");
    newContent = newContent.replace("<h6>Paper Size:A4</h6>","<h6>Paper Size: "+paperSize+"</h6>")
    
   }
  else{
    newContent = newContent.replace("Paper Size: A3", "Paper Size: "+paperSize);
    newContent = newContent.replace("Paper Size:A3", "Paper Size: "+paperSize);
    newContent = newContent.replace(" Paper Size: A3", " Paper Size: "+paperSize);
    newContent = newContent.replace("<strong>Paper Size: A3</strong>","<strong>Paper Size: "+paperSize+"</strong>");
    newContent = newContent.replace("<h6>Paper Size:A3</h6>","<h6>Paper Size:"+paperSize+"</h6>")
  }
   element.innerHTML = newContent;
 }
   //change papersize in info box for large template with info
   var el = document.querySelector(".info-box-top-right");
 
 if(el){
  var cont = el.innerHTML;
  var newCont;
  if(paperSize === 'A3'){
    newCont = cont.replace("@ A4","@ A3")
  }
  else{
    newCont = cont.replace("@ A3","@ A4")
  }
   el.innerHTML = newCont;
   this._mapElement.changePaperSize(this.paperSize,this.customPlanSelected,this.templateSelection,this.scaleSelection);
}
}


setDontShowStatutupMsg(checked){
  this.dontShowStatutupMsg = checked;
  window.localStorage.setItem('dontshowstartupMsg',checked);
}

setDontShowExtMsg(checked){
  this.dontshowExtMsg = checked;
  sessionStorage.setItem('dontshowextmsg',checked);
}

setDontShowPickFeatureMsg(checked){
  this.dontshowPickFeatureMsg = checked;
  sessionStorage.setItem('dontshowpickfeaturemsg',checked);
}

goToMapshop(){
  let url = environment.mapshopUrl+'/product/1839';
  
  if(this._locationXY && this._locationXY.length){
    url = url+'/marker/'+this._locationXY[0]+'/'+this._locationXY[1];
  }
  window.location.href = url;
}

setUploadedFile(target){
  this.logoFile = target.files[0];
  var fileReader = new FileReader();
  fileReader.onload = ()=> {
    var fileContent = fileReader.result;
    var imgElement = document.getElementById("templateLogo");
    imgElement['src'] = fileContent;
    this.logoFileContent = fileContent;
  };

  fileReader.readAsDataURL(this.logoFile);
 
}

saveLogo(){
  if(this.logoFile){
  var file = this.logoFile;
  var fileReader = new FileReader();

  fileReader.onload = ()=> {
    var fileContent = new Uint8Array(fileReader.result as ArrayBuffer);
    var byteArray = Array.from(fileContent);
    
    var params = {
      'Guid' : this._guid,
      'WebChannel' : environment.webChannel,
      'ImageBytes' : byteArray,
      'FileName' : this.logoFile.name
    }
    this._plansService.saveLogo(params).subscribe((response)=>{
     
     this.logoFileContent = response as string;
    })
  };
  fileReader.readAsArrayBuffer(file);
  }
  //change the logo in the template
  let logoImg = document.getElementsByClassName('emapsite-logo').item(0);
  logoImg['src'] = this.logoFileContent;


}

setSelectedLogo(logoUrl , btnElement){
//first set the btn selected
let allSelected = $('.selected');
if(allSelected.length){
allSelected[0].toggleClass('selected');
}

let logoImg = document.getElementsByClassName('emapsite-logo').item(1);
logoImg['src'] = logoUrl;
this.logoFileContent = logoUrl;
}

setSelectedLogoInTemplate(url){
  let logoImg = document.getElementsByClassName('emapsite-logo').item(0);
logoImg['src'] = url;
this.logoFileContent = url;
}

deleteLogo(logo:string){
  //call service to delete logo
  let params = {
    'Guid': this._guid,
    'WebChannel' : environment.webChannel,
    'FileName' : logo
  }

  this._plansService.deleteLogo(params).subscribe(response =>{
    //refresh the list 
    this.logoList = response;
  })

}

dropHandler(ev){
  ev.preventDefault();
  var fileType = ev.dataTransfer.files[0].type;
  fileType = fileType.split('/')[1];
 
  switch(fileType){
    case 'jpeg':
    case 'png':
    case 'tif':
    case 'webp':this.logoFile = ev.dataTransfer.files[0];
                this.setUploadedFile(ev.dataTransfer);
                this.wrongFileType = false;
                break;
    default : console.log("wrong file type");
              this.wrongFileType = true;
  }
  
}

dragOverHandler(event){
  event.preventDefault();
  event.stopPropagation();
  event.dataTransfer.dropEffect = 'copy';
}

dragLeaveHandler(event){
  event.preventDefault();
  event.stopPropagation();
}

setCookieForDisclaimer(){
  //cookie expires in one month
  var expiry = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);

  //set the cookie for the disclaimer seen and check it onload to show/hide the message
  this._cookieService.set('PlansMsgSeen','true',expiry);
}

setSelectedTemplate(size:string,templateChanged:boolean){
 
  if(templateChanged){
  this.paperSize = size;
  }
  //get mapState
  let mapState;
  if(this._mapElement){
  mapState = this._mapElement.getMapState();
  }
  //call getTemplate
  let tempId = this.templateSelection?this.templateSelection:1;
  let paperSz = this.paperSize?this.paperSize:'A4';
  let isCustomPlan = tempId !==1 ?true: paperSz!=='A4'?true:false;
  
  this._plansService.getTemplateHtml(this._guid, tempId).subscribe((response) => {
   let cssName = response['Css'];
   let template = response['TemplateHtml'];
    //get product code
    let params = {
      'TemplateId': this.templateSelection?this.templateSelection:1,
      'PaperSize': this.paperSize?this.paperSize:'A4',
      'Scale' : this.scaleSelection?this.scaleSelection:'1:1250',
      'Colour': this.customPlanType,
      'Orientation': 'Portrait'
    }
 
  this._plansService.getPlansProduct(params).subscribe((response)=>{
    let scalebarUrl = response['ScaleBarUrl']
    
    template = template.replace('<!------ScaleBar------>','<img class="scale-img" src="'+scalebarUrl+'">');
    template = template.replace('<!------Date------>',this.formatDate(new Date()));
   let newTemp = this.setTemplateProperties(template);
   this.originaltemplate = newTemp;
   this.templateHtml = this.createDynamicHtml(newTemp,response['Css']);
   let sub = this.mapLoaded.subscribe(()=>{
    sub.unsubscribe();
    this.templateOptions =
    {
      sanitize: false
    }
    if(this._mapElement)
    {
    this._mapElement.customPlanSelected = isCustomPlan;
    }
    //call show on map and set everything accordingly
    this.changeCss(cssName);
    setTimeout(()=>{
      this._mapElement.customPlanSelected = isCustomPlan;  
      //set legend
      this.addLegendToPlan();
      //set logo
      let logoImg = document.getElementsByClassName('emapsite-logo').item(0);
      logoImg['src'] = this.logoFileContent;
      if(!this.editMode){
      //set notes and title
      this.setTitleAndNotes(this.title,this.notes);
      this.setMapState(mapState);
      }
     
      },1000)
   })
  
  },
  error =>{
    this._errorModal.show();
  })
     
  },
  error =>{
    this._errorModal.show();
  })
}

changeCss(cssName)
{
  const linkElement = document.querySelectorAll(`link[rel="stylesheet"]`);
 
// remove the link element from the head of the document
if (linkElement && linkElement[3]) {
  this._renderer.removeChild(document.head, linkElement[3]);
  
  this._renderer.removeClass(this._elementRef.nativeElement,'map-div');
  const mapDiv = document.querySelector('.map-div');
  this._renderer.removeStyle(mapDiv,'aspect-ratio');
}
 //add css in page html
 const link = this._renderer.createElement('link');
 link.rel = 'stylesheet';
 link.type = 'text/css';
 link.href = 'assets/css/templates/'+cssName;
  this.link = link;
 this._renderer.appendChild(document.head, link);

}

setTemplateProperties(template){
    //set URPN
    //set HMLRTitleNo
    let newTemp = template.replace('[UPRN]', this.uprn);
    newTemp = newTemp.replace('[TitleNo]', this.titleNumber);
    
  //set scale
    newTemp = newTemp.replace('<!------MapCentre------>','<strong>'+this._locationXY+' </strong>');
    newTemp = newTemp.replace('<!------Scale------> @',this._scale+' @');
    newTemp = newTemp.replace('@<!------PaperSize------>','@ '+this.paperSize);
  //set papersize
  let paper = this.paperSize ? this.paperSize : 'A4';
    newTemp = newTemp.replace('<strong>Paper Size:</strong>', '<strong>Paper Size: '+paper+'</strong>');
  return newTemp;
}

  setMapState(mapState) {
    this.showOnMap(this.searchedLocation);
    setTimeout(() => {
      this._mapElement.setMapState(mapState)
    }, 2000);

  }

  setProductsArrayForCustom(ext?:any){
  //store original order
  this.origProductIdsArray = this.productIdsArray;
  this.origProductsArray = this.productsArray;
  //update productarray
  this.productsArray = [];
  this.productIdsArray = [];
  //get product code
  let params = {
    'TemplateId':this.templateSelection? Number(this.templateSelection):1,
    'PaperSize': this.paperSize?this.paperSize:'A4',
    'Scale' : this.scaleSelection && this.scaleSelection!==''?this.scaleSelection:'1:1250',
    'Colour':this.customPlanType,
    'Orientation': 'Portrait'
  }
  this._plansService.getPlansProduct(params).subscribe((response)=>{
    this.productIdsArray.push(response['ProductId']);
    this.productsArray.push(response['Name']);
   
    //get product price and set in price map
   let extent = this._mapElement.getExtentForProduct(response['ProductId'],this.paperSize,this.scaleSelection);
   let newPolygon = fromExtent(extent);
    let newFeature = new Feature({
      geometry: newPolygon,
    });
    var format = new WKT(),
      wkt = format.writeGeometry(newFeature.getGeometry());
    let planProd = {
      'Key': response['ProductId'],
      'Value': wkt
    }
    let params = {
      Guid: this._guid,
      Geom: this._wkt.length?this._wkt[0]:this._wkt,
      ProdIDS: response['ProductId'],
      WebChannel: environment.webChannel,
      PlanID: null,
      PlansProducts: [planProd]
    }
   
    this._productService.GetPrices(params).subscribe((res) => {
      
      //from the response object get product price map
      res['Prices'].forEach(element => {
       if (element.price == '£0.00') {
          this._productPriceMap.set(element.prodid, element.message);
        }
        else {
          this._productPriceMap.set(element.prodid, element.price);
        }
      
      });
    },
    error =>{
      this._errorModal.show();
    })

  })

}

formatDate(date: Date): string {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
}

openFeedbackModal(){
  //open feedback modal
  this._feedbackModal.show();
}

updateLegendColor(change :{'originalColor': string,'changedColor': string}){
  let legend = this.legendArr.find(element=>{
    return element.colour === change.originalColor;
  })
  let index = this.legendArr.indexOf(legend);

  if(index !== -1){
   let txt = legend.text;
   legend.colour = change.changedColor;
   this.legendArr[index] = legend;
   this.addLegendToPlan();
  }

}

getFormattedArea(){
  let area = 0;
  if(this.featureInfo){
    area = this.featureInfo['area'];
  }
  let areaStr = area ? area.toFixed(2) + 'm<sup>2</sup>' : 0;
   return areaStr;
}

getFormattedWidth(){
  let wd = 0;
  if(this.featureInfo){
    wd = this.featureInfo['width'];
  }
  let wdStr = wd.toFixed(2) + 'm';

  return wdStr;
}

getFormattedHeight(){
  let ht = 0;
  if(this.featureInfo){
    ht = this.featureInfo['height'];
  }
  let htStr = ht.toFixed(2) + 'm';
  
  return htStr;
}

getFormattedPerimeter(){
  let peri = 0;
  if(this.featureInfo){
    peri = this.featureInfo['perimeter'];
  }
  let periStr = peri.toFixed(2) + 'm';
  
  return periStr;
}

copyLink(){
  var temp = document.createElement('input')
  var values = document.getElementsByClassName('measurement-value');
  var titles = document.getElementsByClassName('measurement-name');
  for(let i=0;i<values.length;i++){
      temp.value= temp.value + titles.item(i).innerHTML +" "+values.item(i).innerHTML+ ", ";
  }
  temp.value = temp.value.replace("<sup>2</sup>","²");
    document.body.appendChild(temp);
    temp.select();
  navigator.clipboard.writeText(temp.value)
  .then(() => {
    temp.remove();
  })
  .catch(err => {
     console.error('Failed to copy text: ', err);
  });

}

selectPickFeature(){
 
  let setting = sessionStorage.getItem('dontshowpickfeaturemsg');
    if(!this._pickFeatureSelected && (!setting || setting === 'false'))
    {
      this._pickFeatureModal.show();
     
    }
    else{
      //deselect pickfeature with a delay incase mapcomponent is trying to set it from somewhere
      setTimeout(() => {
        let btn = document.getElementById('pickfeature_btn')
        btn.classList.toggle('selected')
        }, 100);
    
    }
    this._pickFeatureSelected = !this._pickFeatureSelected;
    this._mapElement.addPickFeatureInteraction();
}

getCustomProductPrice(){
  let params = {
    'TemplateId':this.templateSelection? Number(this.templateSelection):1,
    'PaperSize': this.paperSize?this.paperSize:'A4',
    'Scale' : this.scaleSelection && this.scaleSelection!==''?this.scaleSelection:'1:1250',
    'Colour':this.customPlanType,
    'Orientation': 'Portrait'
  }
  this._plansService.getPlansProduct(params).subscribe((response)=>{
 
  this.productIdsArray = [response['ProductId']];
  this.productsArray = [response['Name']]
    //get product price and set in price map
   let extent = this._mapElement.getExtentForProduct(response['ProductId'],this.paperSize,this.scaleSelection);
   let newPolygon = fromExtent(extent);
    let newFeature = new Feature({
      geometry: newPolygon,
    });
    var format = new WKT(),
      wkt = format.writeGeometry(newFeature.getGeometry());
    let planProd = {
      'Key': response['ProductId'],
      'Value': wkt
    }
    let params = {
      Guid: this._guid,
      Geom: this._wkt.length?this._wkt[0]:this._wkt,
      ProdIDS: response['ProductId'],
      WebChannel: environment.webChannel,
      PlanID: null,
      PlansProducts: [planProd]
    }
   
    this._productService.GetPrices(params).subscribe((res) => {
      
      //from the response object get product price map
      res['Prices'].forEach(element => {
       if (element.price == '£0.00') {
          this._productPriceMap.set(element.prodid, element.message);
        }
        else {
          this._productPriceMap.set(element.prodid, element.price);
        }
      
      });
    },
    error =>{
      this._errorModal.show();
    })

  })
}
}

