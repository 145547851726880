import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import {SearchType} from '../app/app.model';


@Injectable()
export class SearchService {
    // Make one of these
    constructor(private _httpClient: HttpClient,
      private _cookieService  :CookieService) {}

    Search(searchTypes: SearchType, searchTerm: string, guid :string): Observable<any> {
        let searchUrl = `${environment.servicesUrl}/wcfMappingservice/Mappingservice.svc/api/Search`
        let params = {
            Guid: guid,
            WebChannel: environment.webChannel,
            SearchType: searchTypes,
            SearchArea: searchTerm.trim()
        }
       let apiUsername = 'MappingRestUserName';
       let apiPassword = 'MappingRestPassword';
   
      let encodedCredentials = 'Basic ' + btoa(apiUsername + ':' + apiPassword);
   
      let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': encodedCredentials
          })
          };
         return this._httpClient.post(searchUrl,params,httpOptions).pipe()  

        // return this._httpService.doHttpPostWithCredentials(searchUrl,
        //                                                    params,
        //                                                    ConfigService.environment.configurations.siteConfig.api.mapping.apiUsername,
        //                                                    ConfigService.environment.configurations.siteConfig.api.mapping.apiPassword,
        //                                                    null).pipe(
        //     map((response) => {
        //         return response.map((item) => {
        //             return <ISearchResult>(item);
        //         });
        //     }),
        //     catchError((error) => {
        //         return this._errorService.errorHandler(error, 'Search', 'SearchService.Search', 'There was an error during search.');
        //     })
        // );
        
    }
}
