import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-basket-confirmation',
  templateUrl: './basket-confirmation.component.html',
  styleUrls: ['./basket-confirmation.component.scss']
})
export class BasketConfirmationComponent implements OnInit {
  public basketProducts = [];
  @Input() addToBasketSummaryProducts : any ;
  @Input() addToBasketUpsellProducts : any;
  @Input() numberOfProducts: number;
  @Output() public selectAreaClicked = new EventEmitter<any>();
  @Output() public closeClicked = new EventEmitter<any>();
  hideLink: boolean;
  public imagesPath: string = environment.configurations.uiConfig.imagesPath;
  faInfoCircle = faInfoCircle;

  constructor() { }

  ngOnInit(): void {
    if(this.addToBasketSummaryProducts.length)
    this.basketProducts.push(this.addToBasketSummaryProducts[this.addToBasketSummaryProducts.length-1]) //lastproduct in the list
  }

  viewFullBasket(){
    this.hideLink = true;
    this.basketProducts = this.addToBasketSummaryProducts;
}
ngAfterViewInit(){
  this.basketProducts = this.addToBasketSummaryProducts.length?this.addToBasketSummaryProducts[this.addToBasketSummaryProducts.length-1]:[];
  
}

basketClick() {
    window.location.href = `${environment.basketUrl}`;
   }

reset() {
    // this._router.navigate(['/mapselector'] );
    window.location.reload();

  }

getTotalPrice(){
    let total = 0;
    this.addToBasketSummaryProducts.forEach(element => {
        total = total + element.ProductPrice;
    });
    return total;
}

close(){
 this.basketProducts = [];
 this.hideLink = false;
 this.closeClicked.emit();
}

getArea(prod){
  if(prod['AreaInHA'] >=100){
      return prod['AreaInKM'].toFixed(2) + 'km'
      
  }
  else if(prod['AreaInM'] < 1000){
      return prod['AreaInM'].toFixed(2) + 'm'
  }
  else {
      return prod['AreaInHA'].toFixed(2) + 'ha'
  }
}

showCalled(){
  if(this.addToBasketSummaryProducts.length && this.numberOfProducts){
  for(let i = 1;i<=this.numberOfProducts;i++){
   
  this.basketProducts.push(this.addToBasketSummaryProducts[this.addToBasketSummaryProducts.length-i]) 
  }
  }
}

selectArea(prod:any){
//getExtent from area and save shape and then call url
this.selectAreaClicked.emit(prod);
  
 }
}
