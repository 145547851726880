<div class="e-feedback-form">
    <div *ngIf="this.sendFeedbackInProgress">
        <div class="e-sending-feedback">{{feedbackMsg}}</div>
        <!-- <span *ngIf="this.sendFeedbackInProgress"
                  class="spinner-border text-info slow search-spinner" role="status"></span> -->
    </div>
    <form *ngIf="!this.sendFeedbackInProgress"  >
        <div class="e-feedback-controls" >
           <p style="color:teal">Please provide any feedback you have about using Plans, or if there are any additional features that you would like to see.</p>     
            <!--  <e-info-text *ngIf="this.forRevertToClassicApps" infoText="You're now reverting to {{environment.appFriendlyName}} Classic. Would you mind taking a moment to provide any feedback about your experience of using the new {{environment.appFriendlyName}} app?" ></e-info-text> -->
            <div class="e-panel-subtitle">Your feedback:</div>
         
            <textarea id="user_feedback" class="p-2" #userFeedbackTextArea [(ngModel)]="feedback" [ngModelOptions]="{ standalone: true }" id="user_feedback" maxlength="500" rows="10"></textarea>
            <div class="d-flex m-2">
                <input class="form-check-input " type="checkbox" [(ngModel)]="userAllowContact" [ngModelOptions]="{ standalone: true }" id="allowCheckContect" ><label for="flexCheckDefault" class="mx-2">Allow Contact</label>
            </div>    
            <div class="d-flex m-2">
                <fa-icon [icon]="faInfoCircle" [size]="'2x'" tooltip tooltipText="This is the measurement of the area you have selected for this product"  style="color: #37AFC8;margin-right:0.5rem"></fa-icon><p >Check this box if you don't mind emapsite contacting you about your feedback.</p> 
            </div>
        </div>
        <div class="ok-btn" >
            <!-- <button #cancelButton (click)="this.close()" class="btn btn-secondary" >Cancel</button> -->
            <button #okButton (click)="this.dialogAction()" class="btn btn-primary">OK</button>
        </div>    
    </form>
</div>
